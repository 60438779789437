import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Card,
  TextField,
  InputAdornment,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Paper,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from '@mui/material';
import dayjs from 'dayjs';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import * as actions from '../../redux/actions';
import Loading from '../../components/Loading';
import { Error } from '../../components/Error';
import { ViewTitle } from '../../components/ViewTitle';
import stockHistory from '../../components/PdfFile/stockHistory';
import ExportExcelHistory from '../../components/ExcelDownload/ExportExcelHistory';
import ProductAllHistory from '../../utils/modelutils/ProductAllHistory';
// eslint-disable-next-line import/newline-after-import
import { app as appConfig } from '../../configs';
function ProductHistory({ title, subtitle }) {
  const dispatch = useDispatch();
  const logs = useSelector((state) => state.logs);
  const setting = useSelector((state) => state.setting);
  const warehouse = useSelector((state) => state.warehouse);
  const [searchTerm, setSearchTerm] = useState('');
  const [name, setName] = useState('');
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(25);
  const [total, setTotal] = useState(undefined);
  const [startDate, setStartDate] = useState('');
  const [selectWarehouse, setSelectWarehouse] = useState(warehouse?._id);
  const warehouseType = useSelector((state) => state.warehouseType);

  // console.log(logs.rows);
  console.log('คลัง', selectWarehouse);

  useEffect(() => {
    setTotal(logs?.total);
    return () => {};
  }, [logs]);

  useEffect(() => {
    dispatch(actions.warehouseAll({}));
    return () => {};
  }, []);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      setName(searchTerm);
      setPage(1);
    }, 300);
    return () => clearTimeout(delayDebounceFn);
  }, [searchTerm]);
  useEffect(() => {
    dispatch(
      actions.getAlllog({
        name,
        page,
        size,
        selectWarehouse,
        startDate,
        // warehouse: selectWarehouse,
      }),
    );
    return () => {};
  }, [name, page, size, startDate, warehouse, selectWarehouse]);

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;
  console.log('startDate in log', startDate);
  const handleChangeRowsPerPage = (event) => {
    setSize(event.target.value);
    setPage(1);
  };
  const handlePrint = async () => {
    try {
      await stockHistory(logs, setting);
    } catch (error) {
      alert('ระบบปริ้นมีปัญหา โปรดแจ้งแอดมิน');
    }
  };

  const handleChangeType = (event) => {
    console.log('type warehouse', event.target.value);
    setSelectWarehouse(event.target.value);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1);
  };
  const renderAddButton = () => (
    <div className="flex flex-row justify-end pb-4">
      {/* <div className="pr-2">
        <Button variant="contained" startIcon={<StoreIcon />}>
          จัดการแสดงสินค้า
        </Button>
      </div> */}
      <div>
        <Button
          variant="contained"
          onClick={() => handlePrint()}
          // startIcon={<AddBoxIcon />}
        >
          พิมพ์รายงาน
        </Button>
      </div>
      <div className="my-auto ml-2">
        <ExportExcelHistory
          columnList={ProductAllHistory?.columns}
          currentData={logs?.rows}
          dataAPIEndPoint="logs"
          disableAllExport
          dataQuery={{
            fetchStockLot: false,
            fetchProcess: false,
            page: 1,
            size: appConfig?.maxFetchSize,
          }}
          sheetName="LOGS"
        />
      </div>
    </div>
  );
  const renderSearch = () => (
    <Card>
      <div className="p-4 w-full  flex row">
        <div className="w-full md:w-1/2">
          <TextField
            label="ค้นหา"
            fullWidth
            size={'large'}
            id="outlined-start-adornment"
            onChange={(e) => {
              setSearchTerm(e.target.value);
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <i className="fas fa-search"></i>
                </InputAdornment>
              ),
            }}
          />
        </div>
        <div className="w-full md:w-1/2 pl-1">
          <FormControl sx={{ minWidth: 120 }} fullWidth={true}>
            <InputLabel id="type" size={'small'}>
              เลือกคลัง
            </InputLabel>
            <Select
              label="เลือกคลัง"
              size={'samll'}
              defaultValue={selectWarehouse}
              onChange={handleChangeType}
              fullWidth
            >
              <MenuItem value={''}>แสดงทั้งหมด</MenuItem>
              {_.size(warehouse?.rows) ? (
                _.map(warehouse?.rows, (row) => (
                  <MenuItem key={row.id} value={row.id}>
                    {row?.name}
                  </MenuItem>
                ))
              ) : (
                <MenuItem>
                  <MenuItem disabled value="">
                    <em>ไม่มีข้อมูล</em>
                  </MenuItem>
                </MenuItem>
              )}
            </Select>
          </FormControl>
        </div>
        <div className="w-full flex justify-end  md:w-1/2 pl-1">
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              renderInput={(props) => <TextField {...props} size={'small'} />}
              label="เลือกวันที่"
              inputFormat={'DD MMMM BBBB '}
              value={dayjs(startDate)}
              onChange={(newValue) => {
                setStartDate(newValue);
                // queryDate();
              }}
            />
          </LocalizationProvider>
        </div>
      </div>
    </Card>
  );
  // const renderSearchDate = () => (
  //   <Card className="mb-2">
  //     <div className="w-full p-4 flex flex-row"></div>
  //   </Card>
  // );

  const renderTable = () => (
    <div className="my-2">
      <Paper>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }}>
            <TableHead>
              <TableRow>
                <TableCell>
                  <div className="font-bold">ลำดับที่</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold"> วันที่</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold"> ชื่อสินค้า</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold"> เข้า/ออก</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold"> คลัง</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold"> จำนวน</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold"> ก่อน</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold"> คงเหลือ</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold"> ผู้ดำเนินการ/ลูกค้า</div>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!_.isEmpty(logs.rows) ? (
                logs.rows.map((row, index) => (
                  <TableRow
                    key={row.id}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    className={index % 2 === 0 ? 'bg-gray-100' : ''}
                  >
                    <TableCell component="th" scope="row">
                      {(page - 1) * size + index + 1}
                    </TableCell>
                    <TableCell>
                      {`${dayjs(row?.date).format('D MMM BBBB ')}`}
                      {' , '}
                      {`${dayjs(row?.date).format('HH:mm ')}`}
                    </TableCell>
                    <TableCell>
                      {`${row?.product[0]?.name}`}
                      {row?.option && `( ${row?.option.name} )`}
                    </TableCell>
                    <TableCell>{`${row?.transaction_type}`}</TableCell>
                    <TableCell>{`${
                      row?.warehouse_name?.[0] || 'คลังรวม'
                    }`}</TableCell>
                    <TableCell>{`${row?.quantity}`}</TableCell>
                    <TableCell>{`${row?.prev_quantity}`}</TableCell>
                    <TableCell>{`${row?.next_quantity}`}</TableCell>
                    <TableCell>{`${row?.user}`}</TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={6}>
                    <div className="text-center">ไม่มีข้อมูล</div>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          component="div"
          rowsPerPageOptions={[5, 10, 25, 100]}
          onRowsPerPageChange={handleChangeRowsPerPage}
          page={page - 1}
          count={total || 1}
          rowsPerPage={size}
          onPageChange={handleChangePage}
        />
      </Paper>
    </div>
  );

  if (logs.isLoading || !logs.rows) {
    return <Loading />;
  }
  if (!logs.isLoading && logs.isCompleted) {
    return (
      <div>
        <div className="flex justify-between">
          <div>{renderTitle()}</div>
          <div className="mt-6">{renderAddButton()}</div>
        </div>{' '}
        {renderSearch()}
        {renderTable()}
      </div>
    );
  }
  return <Error message={logs?.message} />;
}

ProductHistory.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

ProductHistory.defaultProps = {
  title: '',
  subtitle: '',
};

export default ProductHistory;
