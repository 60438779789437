import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, Link } from 'react-router-dom';
// eslint-disable-next-line object-curly-newline
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Paper,
  InputAdornment,
} from '@mui/material';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import DescriptionIcon from '@mui/icons-material/Description';
import DeleteIcon from '@mui/icons-material/Delete';

import dayjs from 'dayjs';
import _ from 'lodash';
import PropTypes from 'prop-types';
// import OrderNoVatSlip from '../../components/PdfFile/OrderNoVatSlip';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';

import * as actions from '../../redux/actions';
import Loading from '../../components/Loading';
import { Error } from '../../components/Error';
import { ViewTitle } from '../../components/ViewTitle';
import { BackButton } from 'components/Button';
import HistoryEx from 'components/PdfFile/HistoryEx';

const CheckLot = ({ title, subtitle }) => {
  const dispatch = useDispatch();
  const productTransaction = useSelector((state) => state.productTransaction);

  const history = useHistory();
  const [searchTerm, setSearchTerm] = useState('');
  const [name, setName] = useState('');
  const [page, setPage] = useState(1);
  const [startDate, setStartDate] = useState(new Date());
  const [size, setSize] = useState(10);
  const [total, setTotal] = useState(undefined);
  const transactionType = 'อัพเดทสต็อก';

  useEffect(() => {
    const fetchProductTransaction = async () => {
      try {
        await dispatch(actions.productTransactionReset());
        await dispatch(
          actions.productTransactionAll({
            name,
            page,
            size,
            transactionType,
            withLog: true,
          }),
        );
      } catch (error) {
        console.error(error);
      }
    };
    fetchProductTransaction();
    return () => {};
  }, [name, page, size]);

  useEffect(() => {
    setTotal(productTransaction?.total);
    return () => {};
  }, [productTransaction]);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      setName(searchTerm);
      setPage(1);
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [searchTerm]);

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  const handleChangeRowsPerPage = (event) => {
    setSize(event.target.value);
    setPage(1);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1);
  };

  const handleToInfoPage = async (id) => {
    history.push(`/wms/history-checklot/detail/${id}`);
  };

  const handleDelete = async (id) => {
    const confirm = window.confirm('ยืนยันการลบข้อมูล');
    if (confirm) {
      try {
        await dispatch(actions.productTransactionDelete(id));
        await dispatch(actions.productTransactionAll({ name, page, size }));
      } catch (error) {
        console.error(error);
      }
    }
  };
  const handlePrint = async () => {
    try {
      await HistoryEx(productTransaction.rows);
    } catch (error) {
      alert('ระบบปริ้นมีปัญหา โปรดแจ้งแอดมิน');
    }
  };

  const renderTable = () => (
    <div className="my-2">
      <div className="flex justify-between">
        <div className="justify-start ">
          <BackButton />
        </div>
        <div className="flex justify-end pb-4">
          {/* <Button
            variant="contained"
            onClick={() => handlePrint()}
            // startIcon={<AddBoxIcon />}
          >
            พิมพ์รายงาน
          </Button> */}
        </div>
      </div>
      <Paper>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 800 }}>
            <TableHead>
              <TableRow>
                <TableCell>
                  <div className="font-bold">ลำดับที่</div>
                </TableCell>

                <TableCell>
                  <div className="font-bold">รายการสินค้า</div>
                </TableCell>

                <TableCell>
                  <div className="font-bold">ผู้ตรวจสต็อก</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold">วันดำเนินการ</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold">ดำเนินการ</div>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!_.isEmpty(productTransaction.rows) ? (
                productTransaction.rows.map((row, index) => (
                  <TableRow
                    key={index}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    className={index % 2 === 0 ? 'bg-gray-100' : ''}
                  >
                    <TableCell component="th" scope="row">
                      {(page - 1) * size + index + 1}
                    </TableCell>

                    <TableCell>
                      {row?.order.map((eachOrder, _index) => (
                        <div key={_index} className="w-full py-1 flex">
                          <p className="pr-1 text-red-500 font-bold">
                            {eachOrder?.amount} X
                          </p>
                          <p className="font-bold">
                            {eachOrder?.product?.name}
                          </p>
                        </div>
                      ))}
                    </TableCell>

                    <TableCell>
                      <div className="flex justify-start">
                        <div className="pr-2">{`${row?.employee?.firstname}`}</div>
                        <div className="">{`${row?.employee?.lastname}`}</div>
                      </div>
                    </TableCell>
                    <TableCell>{`${dayjs(row?.date).format(
                      'D MMM BBBB ',
                    )}`}</TableCell>
                    <TableCell>
                      <div className="flex flex-row flex-wrap gap-1">
                        <Button
                          variant="contained"
                          color={'secondary'}
                          startIcon={<DescriptionIcon />}
                          size={'small'}
                          onClick={() => handleToInfoPage(row.id)}
                        >
                          รายละเอียด
                        </Button>
                        <Button
                          variant="contained"
                          color={'error'}
                          size={'small'}
                          startIcon={<DeleteIcon />}
                          onClick={() => {
                            handleDelete(row?.id);
                          }}
                          disabled
                        >
                          ลบ
                        </Button>
                      </div>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={6}>
                    <div className="text-center">ไม่มีข้อมูล</div>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          component="div"
          rowsPerPageOptions={[5, 10, 25, 100]}
          onRowsPerPageChange={handleChangeRowsPerPage}
          page={page - 1}
          count={total || 1}
          rowsPerPage={size}
          onPageChange={handleChangePage}
        />
      </Paper>
    </div>
  );

  if (productTransaction.isLoading || !productTransaction.rows) {
    return <Loading />;
  }
  if (!productTransaction.isLoading && productTransaction.isCompleted) {
    return (
      <div>
        <div className="flex justify-between">
          <div>{renderTitle()}</div>
        </div>
        {renderTable()}
      </div>
    );
  }
  return <Error message={productTransaction?.message} />;
};

CheckLot.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

CheckLot.defaultProps = {
  title: '',
  subtitle: '',
};

export default CheckLot;
