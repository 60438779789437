import pdfMake from 'addthaifont-pdfmake';
import 'addthaifont-pdfmake/build/vfs_fonts';
import buddhistEra from 'dayjs/plugin/buddhistEra';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';
import dayjs from 'dayjs';
import 'dayjs/locale/th';
import _, { each } from 'lodash';

import qrcode from 'qrcode';
import { padding } from '@mui/system';

pdfMake.fonts = {
  Sarabun: {
    normal: 'Sarabun-Light.ttf',
    bold: 'Sarabun-Regular.ttf',
    italics: 'Sarabun-LightItalic.ttf',
    bolditalics: 'Sarabun-Italic.ttf',
  },
};
dayjs.locale('th');
dayjs.extend(buddhistEra);
dayjs.extend(LocalizedFormat);

const MoveWarehousePrint = async (
  paymentTransaction,
  setting,
  ArrayProductList,
  fromPrint,
) => {
  const totalPrice = _.sumBy(
    ArrayProductList,
    (each) => each.price * each?.amount,
  );
  console.log('paymentTransaction in cover print', paymentTransaction);
  console.log('setting in cover print', setting);

  const opts = {
    errorCorrectionLevel: 'H',
    type: 'image/jpeg',
    mode: 'Alphanumeric',
    quality: 0.8,
    margin: 1,
    width: 297,
  };

  const THBText = require('thai-baht-text');

  const address = paymentTransaction?.customer?.address;
  const genBody = (data) => {
    console.log('each amount', data);
    const product = _.map(data, (each, index) => [
      {
        text: index + 1,
      },
      {
        text: `${each?.amount}`,
        alignment: 'center',
      },
      {
        text: `${each?.product?.name}`,
      },
      // {
      //   text: `${(each?.remark && each?.remark) || '-'}`,
      // },
      {
        text: each?.price || '-',
        alignment: 'center',
      },

      {
        text:
          (each?.price * each?.amount || 0)
            .toFixed(2)
            .replace(/\d(?=(\d{3})+\.)/g, '$&,') || '-',
        alignment: 'right',
      },
      {
        text: '',
        border: [false, false, false, false],
      },
      {
        text: index + 1,
      },
      {
        text: `${each?.amount}`,
        alignment: 'center',
      },
      {
        text: `${each?.product?.name}`,
      },
      // {
      //   text: `${(each?.remark && each?.remark) || '-'}`,
      // },
      {
        text: each?.price || '-',
        alignment: 'center',
      },

      {
        text:
          (each?.price * each?.amount || 0)
            .toFixed(2)
            .replace(/\d(?=(\d{3})+\.)/g, '$&,') || '-',
        alignment: 'right',
      },
      // {
      //   text:
      //     fromPrint === 'export'
      //       ? (each?.price * each?.amount)
      //           .toFixed(2)
      //           .replace(/\d(?=(\d{3})+\.)/g, '$&,')
      //       : ((each?.price - each?.discount || 0) * each?.quantity)
      //           .toFixed(2)
      //           .replace(/\d(?=(\d{3})+\.)/g, '$&,'),
      //   alignment: 'right',
      // },
    ]);
    return product;
  };

  const genItemBody = genBody(
    paymentTransaction?.order || paymentTransaction?.order_id?.orders,
  );

  const genQR = (data) => {
    let resQR;
    qrcode.toDataURL(data, opts, (err, res) => {
      if (err) throw err;
      resQR = res;
    });
    return resQR;
  };

  const invoiceContent = (headerTH1, headerTH2, headerEng1, headerEng2) => [
    {
      table: {
        widths: ['50%', '50%'],
        margin: [0, 0, 0, 10],
        body: [
          [
            {
              text: headerTH1 || 'ใบส่งของ',
              border: [false, false, false, false],
              bold: true,
              fontSize: '14',
              margin: [-12, 0, 0, 2],
              alignment: 'center',
            },
            {
              text: headerTH2 || 'ใบส่งของ (สำเนา 1)',
              alignment: 'center',
              fontSize: '14',
              margin: [-5, 0, 0, 0],
              bold: true,
              border: [false, false, false, false],
            },
          ],
        ],
      },
    },
    {
      margin: [0, 10],
      table: {
        // headers are automatically repeated if the table spans over multiple pages
        // you can declare how many rows should be treated as headers
        headerRows: 1,
        widths: ['16.67%', '16.67%', '16.67%', '16.67%', '16.67%', '16.67%'],
        body: [
          [
            {
              text: `เล่มที  ${paymentTransaction?.volume_no || ''}`,
              border: [false, false, false, false],
              bold: true,
            },

            {
              text: headerEng1 || '(INVOICE))',
              fontSize: '14',
              bold: true,
              border: [false, false, false, false],
              margin: [23, -10, 0, 0],
            },
            {
              text: `เลขที่  ${paymentTransaction?.bill_no || ''}`,
              alignment: 'center',
              border: [false, false, false, false],
              bold: true,
            },

            {
              text: `เล่มที่  ${paymentTransaction?.volume_no || ''}`,

              border: [false, false, false, false],
            },

            {
              text: headerEng2 || '(INVOICE COPY 1)',
              fontSize: '14',
              margin: [0, -10, 0, 0],
              alignment: 'center',
              bold: true,
              border: [false, false, false, false],
            },
            {
              text: `เลขที่  ${paymentTransaction?.bill_no || ''}`,
              alignment: 'center',
              margin: [26, 0, 0, 0],
              bold: true,
              border: [false, false, false, false],
            },
          ],
          [
            { text: '', border: [false, false, false, false] },
            { text: '', border: [false, false, false, false] },
            {
              text: `วันที่\t${
                dayjs(new Date(paymentTransaction?.createdAt))
                  .locale('th')
                  .format('DD/MM/BBBB') || '-'
              }`,
              bold: true,
              border: [false, false, false, false],
              margin: [0, 10, 0, 0],
            },
            { text: '', border: [false, false, false, false] },
            { text: '', border: [false, false, false, false] },
            {
              text: `วันที่\t${
                dayjs(new Date(paymentTransaction?.createdAt))
                  .locale('th')
                  .format('DD/MM/BBBB') || '-'
              }`,
              bold: true,
              alignment: 'center',
              border: [false, false, false, false],
              margin: [0, 10, 0, 0],
            },
          ],

          [
            {
              text: `ชื่อผู้ซื้อ     ${paymentTransaction?.customer?.name}`,
              style: '',
              border: [false, false, false, false],

              margin: [0, 10, 0, 0],
            },
            { text: '', border: [false, false, false, false] },
            {
              text: `เงื่อนไขการชำระเงิน    ${paymentTransaction?.customer?.payment}`,
              border: [false, false, false, false],
              margin: [0, 10, 0, 0],
              alignment: 'left',
            },
            {
              text: `ชื่อผู้ซื้อ\n${paymentTransaction?.customer?.name}`,
              style: '',
              border: [false, false, false, false],
              margin: [0, 10, 0, 0],
            },
            { text: '', border: [false, false, false, false] },
            {
              text: 'เงื่อนไขการชำระเงิน',
              margin: [0, 10, 0, 0],
              border: [false, false, false, false],
              alignment: 'left',
            },
          ],

          [
            //customer?.address?.topic

            {
              text: `ที่อยู่\t${address?.house_number || ''} ถนน ${
                address?.road || ''
              } หมู่ ${address?.village_number || ''} ตำบล ${
                address?.subdistrict || ''
              } อำเภอ ${address?.district || ''} จังหวัด ${
                address?.province || ''
              } ${address?.postcode || ''}   `,
              border: [false, false, false, false],
              margin: [0, 10, 0, 0],
            },

            // : [
            //     {
            //       text: `ที่อยู่\t${address?.house_number && ''} ถนน ${
            //         address?.road && ''
            //       } หมู่ ${address?.village_number && ''} ตำบล ${
            //         address?.subdistrict && ''
            //       } อำเภอ ${address?.district && '-'} จังหวัด ${
            //         address?.province && '-'
            //       } ${address?.postcode && ''}   `,
            //       border: [false, false, false, false],
            //     },
            //   ],
            { text: '', border: [false, false, false, false] },
            { text: '', border: [false, false, false, false] },
            {
              text: `ที่อยู่\t${address?.house_number || ''} ถนน ${
                address?.road || ''
              } หมู่ ${address?.village_number || ''} ตำบล${
                address?.subdistrict || ''
              } อำเภอ${address?.district || ''} จังหวัด${
                address?.province || ''
              } ${address?.postcode || ''}`,
              border: [false, false, false, false],
              margin: [0, 10, 0, 0],
            },
            { text: '', border: [false, false, false, false] },
            { text: '', border: [false, false, false, false] },
          ],
        ],
      },
    },
    {
      margin: [0, 40, 40, 0],
      padding: [40, 0, 0, 0],
      table: {
        // headers are automatically repeated if the table spans over multiple pages
        // you can declare how many rows should be treated as headers
        headerRows: 1,

        widths: [
          '4%',
          '5%',
          '28%',
          '4.5%',
          '7%',
          '%',
          '4%',
          '5%',
          '28%',
          '4.5%',
          '7%',
        ],

        body: [
          [
            {
              text: 'ลำดับ',
              style: 'tableHeader',
              border: [true, true, true, true],
            },
            {
              text: 'จำนวน',
              style: 'tableHeader',
              border: [true, true, true, true],
            },
            {
              text: 'รายการ',
              style: 'tableHeader',
              alignment: 'center',
              border: [true, true, true, true],
            },
            {
              text: 'ราคา',
              border: [true, true, true, true],
              style: 'tableHeader',
              alignment: 'center',
            },
            {
              text: 'จำนวนเงิน',
              style: 'tableHeader',
              border: [true, true, true, true],
              alignment: 'center',
            },
            {
              text: '',

              border: [false, false, false, false],
            },
            {
              text: 'ลำดับ',
              style: 'tableHeader',
              border: [true, true, true, true],
            },
            {
              text: 'จำนวน',
              style: 'tableHeader',
              border: [true, true, true, true],
            },
            {
              text: 'รายการ',
              style: 'tableHeader',
              alignment: 'center',
              border: [true, true, true, true],
            },
            {
              text: 'ราคา',
              border: [true, true, true, true],
              style: 'tableHeader',
              alignment: 'center',
            },
            {
              text: 'จำนวนเงิน',
              style: 'tableHeader',
              border: [true, true, true, true],
              alignment: 'center',
            },
          ],
          ...genBody(
            paymentTransaction?.order || paymentTransaction?.order_id?.orders,
          ),
        ],
      },
    },

    {
      margin: [0, -1, 40, 20],
      table: {
        // headers are automatically repeated if the table spans over multiple pages
        // you can declare how many rows should be treated as headers
        headerRows: 1,

        widths: [
          '10.1%',
          '27.25%',
          '4.4%',
          '6.8%',
          '1.95%',
          '10.1%',
          '27.25%',
          '4.4%',
          '6.8%',
        ],

        body: [
          [
            {
              text: 'จำนวนเงิน',
              style: 'tableHeader',
              alignment: 'center',
              border: [true, true, true, true],
            },
            {
              text: `${THBText(totalPrice)}`,
              style: 'tableHeader',
              alignment: 'center',
              border: [true, true, true, true],
            },
            {
              text: 'รวมเงิน',
              border: [true, true, true, true],
              style: 'tableHeader',
              alignment: 'center',
            },
            {
              text:
                totalPrice.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') ||
                '-',
              alignment: 'center',
              border: [true, true, true, true],
            },
            {
              text: '',
              style: 'tableHeader',
              border: [false, false, false, false],
              alignment: 'center',
            },
            {
              text: 'จำนวนเงิน',
              style: 'tableHeader',
              alignment: 'center',
              border: [true, true, true, true],
              style: 'tableHeader',
            },
            {
              text: `${THBText(totalPrice)}`,
              alignment: 'center',
              border: [true, true, true, true],
            },
            {
              text: 'รวมเงิน',
              border: [true, true, true, true],
              style: 'tableHeader',
              alignment: 'center',
            },
            {
              text:
                totalPrice.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') ||
                '-',
              style: 'tableHeader',
              border: [true, true, true, true],
              alignment: 'center',
            },
          ],
        ],
      },
    },

    {
      margin: [20, 40, 20, 0],
      table: {
        widths: ['25%', '25%', '25%', '25%'],
        body: [
          [
            {
              text: 'ข้าพเจ้าได้รับสินค้าตามที่ระบุข้างต้นนี้',
              border: [false, false, false, false],
            },
            {
              text: '',
              border: [false, false, false, false],
            },
            {
              text: 'ข้าพเจ้าได้รับสินค้าตามที่ระบุข้างต้นนี้',
              border: [false, false, false, false],
            },
            {
              text: '',
              border: [false, false, false, false],
            },
          ],

          [
            {
              text: 'โดยถูกต้องเรียบร้อย',
              border: [false, false, false, false],
              margin: [0, 0, 0, 30],
            },
            {
              text: '',
              border: [false, false, false, false],
            },
            {
              text: 'โดยถูกต้องเรียบร้อย',
              border: [false, false, false, false],
              margin: [0, 0, 0, 30],
            },
            {
              text: '',
              border: [false, false, false, false],
            },
          ],

          // [
          //   {
          //     text: '',
          //     border: [false, false, false, false],
          //   },
          //   {
          //     text: '',
          //     border: [false, false, false, false],
          //   },
          // ],
          [
            {
              text: '____________________________________ ผู้รับของ',
              border: [false, false, false, false],
            },
            {
              text: '____________________________________ ผู้ส่งของ',
              border: [false, false, false, false],
            },
            {
              text: '____________________________________ ผู้รับของ',
              border: [false, false, false, false],
            },
            {
              text: '____________________________________ ผู้ส่งของ',
              border: [false, false, false, false],
            },
          ],
          [
            {
              text: ` ${
                dayjs(new Date(paymentTransaction?.createdAt))
                  .locale('th')
                  .format('DD/MM/BBBB') || '-'
              }`,
              margin: [35, 0, 0, 0],
              bold: true,
              border: [false, false, false, false],
            },
            {
              text: '',
              border: [false, false, false, false],
            },
            {
              text: ` ${
                dayjs(new Date(paymentTransaction?.createdAt))
                  .locale('th')
                  .format('DD/MM/BBBB') || '-'
              }`,
              margin: [35, 0, 0, 0],
              bold: true,
              border: [false, false, false, false],
            },

            {
              text: '',
              border: [false, false, false, false],
            },
          ],
        ],
      },
      pageBreak: 'after',
    },
  ];
  const documentRef = {
    pageSize: 'A4',
    pageOrientation: 'landscape',
    pageMargins: [20, 30, 20, 20],
    defaultStyle: {
      font: 'Sarabun',
    },

    content: [
      ...invoiceContent(
        'ใบส่งของ',
        'ใบส่งของ (สำเนา 1)',
        '(INVOICE)',
        '(INVOICE COPY 1)',
      ),
      ...invoiceContent(
        'ใบส่งของ (สำเนา 2)',
        'ใบส่งของ (สำเนา 3)',
        '(INVOICE COPY 2)',
        '(INVOICE COPY 3)',
      ),
    ],

    footer: [],
  };

  pdfMake.createPdf(documentRef).open();
};

export default MoveWarehousePrint;
