import pdfMake from 'addthaifont-pdfmake';
import 'addthaifont-pdfmake/build/vfs_fonts';
import buddhistEra from 'dayjs/plugin/buddhistEra';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';
import dayjs from 'dayjs';
import 'dayjs/locale/th';
import _, { each } from 'lodash';

import qrcode from 'qrcode';
import { padding } from '@mui/system';

pdfMake.fonts = {
  Sarabun: {
    normal: 'Sarabun-Light.ttf',
    bold: 'Sarabun-Regular.ttf',
    italics: 'Sarabun-LightItalic.ttf',
    bolditalics: 'Sarabun-Italic.ttf',
  },
};
dayjs.locale('th');
dayjs.extend(buddhistEra);
dayjs.extend(LocalizedFormat);

const InvoiceNew = async (
  paymentTransaction,
  setting,
  ArrayProductList,
  fromPrint,
) => {
  const totalPrice = _.sumBy(
    ArrayProductList,
    (each) => each.price * each?.amount,
  );
  console.log('paymentTransaction in cover print', paymentTransaction);
  console.log('setting in cover print', setting);

  const opts = {
    errorCorrectionLevel: 'H',
    type: 'image/jpeg',
    mode: 'Alphanumeric',
    quality: 0.8,
    margin: 1,
    width: 297,
  };

  const THBText = require('thai-baht-text');

  const address = paymentTransaction?.customer?.address;
  const genBody = (data) => {
    console.log('each amount', data);
    const product = _.map(data, (each, index) => [
      {
        text: index + 1,
        alignment: 'center',
      },
      {
        text: `${each?.amount}`,
        alignment: 'center',
      },
      {
        text: `${each?.product?.name}`,
        alignment: 'center',
      },
      // {
      //   text: `${(each?.remark && each?.remark) || '-'}`,
      // },
      {
        text: each?.price || '-',
        alignment: 'center',
      },

      {
        text:
          (each?.price * each?.amount || 0)
            .toFixed(2)
            .replace(/\d(?=(\d{3})+\.)/g, '$&,') || '-',
        alignment: 'center',
      },
      {
        text: '',
        border: [false, false, false, false],
      },
      {
        text: index + 1,
        alignment: 'center',
      },
      {
        text: `${each?.amount}`,
        alignment: 'center',
      },
      {
        text: `${each?.product?.name}`,
        alignment: 'center',
      },
      // {
      //   text: `${(each?.remark && each?.remark) || '-'}`,
      // },
      {
        text: each?.price || '-',
        alignment: 'center',
      },

      {
        text:
          (each?.price * each?.amount || 0)
            .toFixed(2)
            .replace(/\d(?=(\d{3})+\.)/g, '$&,') || '-',
        alignment: 'center',
      },
      // {
      //   text:
      //     fromPrint === 'export'
      //       ? (each?.price * each?.amount)
      //           .toFixed(2)
      //           .replace(/\d(?=(\d{3})+\.)/g, '$&,')
      //       : ((each?.price - each?.discount || 0) * each?.quantity)
      //           .toFixed(2)
      //           .replace(/\d(?=(\d{3})+\.)/g, '$&,'),
      //   alignment: 'right',
      // },
    ]);
    return product;
  };

  const genItemBody = genBody(
    paymentTransaction?.order || paymentTransaction?.order_id?.orders,
  );

  const genQR = (data) => {
    let resQR;
    qrcode.toDataURL(data, opts, (err, res) => {
      if (err) throw err;
      resQR = res;
    });
    return resQR;
  };

  const invoiceContent = (
    headerTH1,
    headerTH2,
    headerEng1,
    headerEng2,
    recheck,
  ) => [
    {
      table: {
        widths: ['50%', '50%'],
        margin: [0, 0, 0, 10],
        body: [
          [
            {
              text: headerTH1 || 'ใบส่งของ',
              border: [false, false, false, false],
              bold: true,
              fontSize: '14',
              margin: [0, 0, 0, 0],
              alignment: 'center',
            },
            {
              text: headerTH2 || 'ใบส่งของ (สำเนา 1)',
              alignment: 'center',
              fontSize: '14',
              margin: [0, 0, 0, 0],
              bold: true,
              border: [false, false, false, false],
            },
          ],
        ],
      },
    },
    {
      margin: [0, 10],
      table: {
        // headers are automatically repeated if the table spans over multiple pages
        // you can declare how many rows should be treated as headers
        headerRows: 1,
        widths: ['16.67%', '16.67%', '16.67%', '16.67%', '16.67%', '16.67%'],
        body: [
          [
            {
              text: `เล่มที่    ${paymentTransaction?.volume_no || ''}`,
              border: [false, false, false, false],
              fontSize: '11',
              bold: true,
              margin: [0, 0, 0, 12],
            },

            {
              text: headerEng1 || '(INVOICE)',
              fontSize: '14',
              alignment: 'center',
              bold: true,
              border: [false, false, false, false],
              margin: [0, -10, 0, 0],
            },
            {
              text: `เลขที่  ${paymentTransaction?.bill_no || ''}`,
              alignment: 'center',
              fontSize: '11',
              bold: true,
              border: [false, false, false, false],
            },

            {
              text: `เล่มที่  ${paymentTransaction?.volume_no || ''}`,
              border: [false, false, false, false],
              fontSize: '11',
              bold: true,
            },

            {
              text: headerEng2 || '(INVOICE COPY 1)',
              fontSize: '14',
              margin: [-4, -10, 0, 0],
              alignment: 'center',
              bold: true,
              border: [false, false, false, false],
            },
            {
              text: `เลขที่  ${paymentTransaction?.bill_no || ''}`,
              alignment: 'center',
              fontSize: '11',
              bold: true,
              margin: [26, 0, 0, 0],
              border: [false, false, false, false],
            },
          ],
          [
            { text: '', border: [false, false, false, false] },
            { text: '', border: [false, false, false, false] },
            {
              text: `วันที่\t${
                dayjs(new Date(paymentTransaction?.createdAt))
                  .locale('th')
                  .format('DD/MM/BBBB') || '-'
              }`,
              bold: true,
              border: [false, false, false, false],
              margin: [0, 10, 0, 0],
            },
            { text: '', border: [false, false, false, false] },
            { text: '', border: [false, false, false, false] },
            {
              text: `วันที่\t${
                dayjs(new Date(paymentTransaction?.createdAt))
                  .locale('th')
                  .format('DD/MM/BBBB') || '-'
              }`,
              bold: true,
              alignment: 'center',
              border: [false, false, false, false],
              margin: [0, 10, 0, 0],
            },
          ],
        ],
      },
    },
    {
      margin: [0, -4, 0, 0],
      table: {
        widths: [
          '8.25%',
          '16.25%',
          '14.25%',
          '10.25%',
          '2%',
          '8.25%',
          '16.25%',
          '14.25%',
          '10.25%',
        ],
        alignment: 'center',
        body: [
          [
            {
              text: 'ชื่อผู้ซื้อ',
              alignment: 'left',
              fontSize: 11,
              bold: true,
              border: [false, false, false, false],
              margin: [0, 10, 0, 0],
            },
            {
              text: `${paymentTransaction?.customer?.name || ''}`,
              alignment: 'left',
              fontSize: 11,
              border: [false, false, false, false],
              margin: [0, 10, 0, 0],
            },
            {
              text: ' เงื่อนไขการชำระเงิน',
              border: [false, false, false, false],
              margin: [0, 10, 0, 0],
              bold: true,
              fontSize: 11,
              alignment: 'left',
            },
            {
              text: `${paymentTransaction?.customer?.payment || ''} `,
              border: [false, false, false, false],
              margin: [0, 10, 0, 0],
              fontSize: 11,
              alignment: 'left',
            },
            { text: '', border: [false, false, false, false] },
            {
              text: 'ชื่อผู้ซื้อ',
              alignment: 'left',
              fontSize: 11,
              bold: true,
              border: [false, false, false, false],
              margin: [-10, 10, 0, 0],
            },
            {
              text: `${paymentTransaction?.customer?.name || ''}`,
              alignment: 'left',
              fontSize: 11,
              border: [false, false, false, false],
              margin: [0, 10, 0, 0],
            },

            {
              text: 'เงื่อนไขการชำระเงิน',
              margin: [0, 10, 0, 0],
              fontSize: 11,
              bold: true,
              border: [false, false, false, false],
              alignment: 'left',
            },
            {
              text: `${paymentTransaction?.customer?.payment || ''} `,
              border: [false, false, false, false],
              fontSize: 11,
              margin: [0, 10, 0, 0],
              alignment: 'left',
            },
          ],
          [
            {
              text: 'ที่อยู่',
              alignment: 'left',
              fontSize: 11,
              bold: true,
              border: [false, false, false, false],
              margin: [0, 10, 0, 0],
            },
            {
              text: `${paymentTransaction?.customer?.address || ''}`,
              alignment: 'left',
              fontSize: 11,
              colSpan: 3,
              border: [false, false, false, false],
              margin: [0, 10, 0, 0],
            },
            {
              text: '',
              border: [false, false, false, false],
              margin: [0, 10, 0, 0],
              bold: true,
              fontSize: 11,
              alignment: 'left',
            },
            {
              text: '',
              border: [false, false, false, false],
              margin: [0, 10, 0, 0],
              fontSize: 11,
              alignment: 'left',
            },
            { text: '', border: [false, false, false, false] },
            {
              text: 'ที่อยู่',
              alignment: 'left',
              fontSize: 11,
              bold: true,
              border: [false, false, false, false],
              margin: [-10, 10, 0, 0],
            },
            {
              text: `${paymentTransaction?.customer?.address || ''}`,
              alignment: 'left',
              fontSize: 11,
              colSpan: 3,
              border: [false, false, false, false],
              margin: [0, 10, 0, 0],
            },

            {
              text: '',
              margin: [0, 10, 0, 0],
              fontSize: 11,
              bold: true,
              border: [false, false, false, false],
              alignment: 'left',
            },
            {
              text: '',
              border: [false, false, false, false],
              fontSize: 11,
              margin: [0, 10, 0, 0],
              alignment: 'left',
            },
          ],
        ],
      },
    },
    {
      margin: [0, 12, 40, 0],
      table: {
        // headers are automatically repeated if the table spans over multiple pages
        // you can declare how many rows should be treated as headers
        headerRows: 1,

        widths: [
          '5%',
          '5%',
          '25%',
          '6.5%',
          '9%',
          '5%',
          '5%',
          '5%',
          '25%',
          '6.5%',
          '9%',
        ],
        body: [
          [
            {
              text: 'ลำดับ',
              fontSize: 11,
              alignment: 'center',
              bold: true,
              border: [true, true, true, true],
            },
            {
              text: 'จำนวน',
              fontSize: 11,
              alignment: 'center',
              bold: true,
              border: [true, true, true, true],
            },
            {
              text: 'รายการ',
              fontSize: 11,
              bold: true,
              alignment: 'center',
              border: [true, true, true, true],
            },
            {
              text: 'ราคา',
              border: [true, true, true, true],
              fontSize: 11,
              bold: true,
              alignment: 'center',
            },
            {
              text: 'จำนวนเงิน',
              fontSize: 11,
              bold: true,
              border: [true, true, true, true],
              alignment: 'center',
            },
            {
              text: '',
              magin: [0, 0, 40, 0],
              border: [false, false, false, false],
            },
            {
              text: 'ลำดับ',
              fontSize: 11,
              bold: true,
              alignment: 'center',
              border: [true, true, true, true],
            },
            {
              text: 'จำนวน',
              fontSize: 11,
              bold: true,
              alignment: 'center',
              border: [true, true, true, true],
            },
            {
              text: 'รายการ',
              fontSize: 11,
              bold: true,
              alignment: 'center',
              border: [true, true, true, true],
            },
            {
              text: 'ราคา',
              border: [true, true, true, true],
              fontSize: 11,
              bold: true,
              alignment: 'center',
            },
            {
              text: 'จำนวนเงิน',
              fontSize: 11,
              bold: true,
              border: [true, true, true, true],
              alignment: 'center',
            },
          ],
          ...genBody(
            paymentTransaction?.order || paymentTransaction?.order_id?.orders,
          ),
        ],
      },
    },

    {
      margin: [0, -1, 40, 20],
      table: {
        // headers are automatically repeated if the table spans over multiple pages
        // you can declare how many rows should be treated as headers
        headerRows: 1,

        widths: [
          '5%',
          '5%',
          '25%',
          '6.5%',
          '9%',
          '5%',
          '5%',
          '5%',
          '25%',
          '6.5%',
          '9%',
        ],

        body: [
          [
            {
              text: 'จำนวนเงิน',
              style: 'tableHeader',
              alignment: 'center',
              colSpan: 2,
              fontSize: 11,
              border: [true, false, true, true],
            },
            {
              text: '',
              border: [true, false, true, true],
            },

            {
              text: `${THBText(totalPrice)}`,
              fontSize: 11,
              alignment: 'center',
              border: [true, false, true, true],
            },
            {
              text: 'รวมเงิน',
              border: [true, false, true, true],
              fontSize: 11,
              alignment: 'center',
            },
            {
              text:
                totalPrice.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') ||
                '-',
              alignment: 'center',
              fontSize: 11,
              border: [true, false, true, true],
            },
            {
              text: '',
              style: 'tableHeader',
              border: [false, false, false, false],
              alignment: 'center',
            },
            {
              text: 'จำนวนเงิน',
              fontSize: 11,
              alignment: 'center',
              colSpan: 2,
              border: [true, false, true, true],
              style: 'tableHeader',
            },
            { text: '' },
            {
              text: `${THBText(totalPrice)}`,
              alignment: 'center',
              fontSize: 11,
              border: [true, false, true, true],
            },
            {
              text: 'รวมเงิน',
              border: [true, false, true, true],
              style: 'tableHeader',
              fontSize: 11,
              alignment: 'center',
            },

            {
              text:
                totalPrice.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') ||
                '-',
              fontSize: 11,
              border: [true, false, true, true],
              alignment: 'center',
            },
          ],
        ],
      },
    },

    recheck === true
      ? {
          margin: [20, 10, 20, 0],
          table: {
            widths: ['25%', '25%', '25%', '25%'],
            body: [
              [
                {
                  text: 'ข้าพเจ้าได้รับสินค้าตามที่ระบุข้างต้นนี้',
                  border: [false, false, false, false],
                },
                {
                  text: '',
                  border: [false, false, false, false],
                },
                {
                  text: 'ข้าพเจ้าได้รับสินค้าตามที่ระบุข้างต้นนี้',
                  border: [false, false, false, false],
                },
                {
                  text: '',
                  border: [false, false, false, false],
                },
              ],

              [
                {
                  text: 'โดยถูกต้องเรียบร้อย',
                  border: [false, false, false, false],
                  margin: [0, 0, 0, 30],
                },
                {
                  text: '',
                  border: [false, false, false, false],
                },
                {
                  text: 'โดยถูกต้องเรียบร้อย',
                  border: [false, false, false, false],
                  margin: [0, 0, 0, 30],
                },
                {
                  text: '',
                  border: [false, false, false, false],
                },
              ],
            ],
          },
          pageBreak: 'after',
        }
      : {
          margin: [20, 10, 20, 0],
          table: {
            widths: ['25%', '25%', '25%', '25%'],
            body: [
              [
                {
                  text: 'ข้าพเจ้าได้รับสินค้าตามที่ระบุข้างต้นนี้',
                  border: [false, false, false, false],
                },
                {
                  text: '',
                  border: [false, false, false, false],
                },
                {
                  text: 'ข้าพเจ้าได้รับสินค้าตามที่ระบุข้างต้นนี้',
                  border: [false, false, false, false],
                },
                {
                  text: '',
                  border: [false, false, false, false],
                },
              ],

              [
                {
                  text: 'โดยถูกต้องเรียบร้อย',
                  border: [false, false, false, false],
                  margin: [0, 0, 0, 30],
                },
                {
                  text: '',
                  border: [false, false, false, false],
                },
                {
                  text: 'โดยถูกต้องเรียบร้อย',
                  border: [false, false, false, false],
                  margin: [0, 0, 0, 30],
                },
                {
                  text: '',
                  border: [false, false, false, false],
                },
              ],
            ],
          },
        },
  ];

  const documentRef = {
    pageSize: 'A4',
    pageOrientation: 'landscape',
    pageMargins: [20, 30, 20, 20],
    defaultStyle: {
      font: 'Sarabun',
    },

    content: [
      invoiceContent(
        'ใบส่งของ',
        'ใบส่งของ (สำเนา 1)',
        '(INVOICE)',
        '(INVOICE COPY 1)',
        true,
      ),
      invoiceContent(
        'ใบส่งของ (สำเนา 2)',
        'ใบส่งของ (สำเนา 3)',
        '(INVOICE COPY 2)',
        '(INVOICE COPY 3)',
        false,
      ),
    ],
    footer: [
      {
        margin: [20, -40, 20, 0],
        table: {
          alignment: '',
          widths: ['25%', '25%', '25%', '25%'],
          body: [
            [
              {
                text: '____________________________________ ผู้รับของ',
                border: [false, false, false, false],
              },
              {
                text: '____________________________________ ผู้ส่งของ',
                border: [false, false, false, false],
              },
              {
                text: '____________________________________ ผู้รับของ',
                border: [false, false, false, false],
              },
              {
                text: '____________________________________ ผู้ส่งของ',
                border: [false, false, false, false],
              },
            ],
            [
              {
                text: ` ${
                  dayjs(new Date(paymentTransaction?.createdAt))
                    .locale('th')
                    .format('DD/MM/BBBB') || '-'
                }`,
                margin: [35, 0, 0, 0],
                bold: true,
                border: [false, false, false, false],
              },
              {
                text: '',
                border: [false, false, false, false],
              },
              {
                text: ` ${
                  dayjs(new Date(paymentTransaction?.createdAt))
                    .locale('th')
                    .format('DD/MM/BBBB') || '-'
                }`,
                margin: [35, 0, 0, 0],
                bold: true,
                border: [false, false, false, false],
              },

              {
                text: '',
                border: [false, false, false, false],
              },
            ],
          ],
        },
      },
    ],
  };

  pdfMake.createPdf(documentRef).open();
};

export default InvoiceNew;
