/* eslint-disable implicit-arrow-linebreak */
import pdfMake from 'addthaifont-pdfmake';
import 'addthaifont-pdfmake/build/vfs_fonts';
import buddhistEra from 'dayjs/plugin/buddhistEra';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';
import dayjs from 'dayjs';
import _, { each } from 'lodash';

pdfMake.fonts = {
  Sarabun: {
    normal: 'Sarabun-Light.ttf',
    bold: 'Sarabun-Regular.ttf',
    italics: 'Sarabun-LightItalic.ttf',
    bolditalics: 'Sarabun-Italic.ttf',
  },
};
dayjs.locale('th');
dayjs.extend(buddhistEra);
dayjs.extend(LocalizedFormat);
const genBody = (productTransaction) => {
  const productList = _.map(productTransaction.rows, (product, index) => [
    { text: index + 1, alignment: 'center' },
    {
      text: dayjs(product?.date).format('DD MMM BB '),
      alignment: 'center',
    },
    { text: product?.bill_no, alignment: 'center' },

    { text: `${product?.customer?.name || '-'}\n`, alignment: 'center' },
    {
      text: `${_.map(
        product?.order,
        (order) =>
          `${order?.product.name} ${order?.amount} ${order?.product?.unit}\n`,
      )}`,
      alignment: 'center',
    },
    {
      text: `${
        (product?.total_price_offline_out_before +
          product?.discount_bill_shipping &&
          product?.total_price_offline_out_before +
            product?.discount_bill_shipping -
            product?.discount_bill) ||
        product?.total_price_offline_out_before +
          product?.discount_bill_shipping -
          product?.discount_bill_percent ||
        '-'
      } บาท \n`,
      alignment: 'center',
    },
  ]);
  return productList;
};

const HistoryPos = async (productTransaction, setting) => {
  // eslint-disable-next-line global-require
  console.log('filterHistoryWarehouse', productTransaction);

  const genItemBody = genBody(productTransaction);
  const documentRef = {
    pageSize: 'A4',
    pageOrientation: 'portait',
    pageMargins: [20, 100, 20, 0],
    defaultStyle: {
      font: 'Sarabun',
      fontSize: '10',
    },
    header: [
      {
        margin: [20, 4, 20, 0],
        alignment: 'center',
        table: {
          widths: ['*'],
          body: [
            [
              {
                margin: [0, 50, 0, 0],
                text: 'รายการสั่งซื้อหน้าร้าน',
                fontSize: 16,
                bold: true,
                border: [false, false, false, false],
                alignment: 'center',
              },
            ],
          ],
        },
      },
    ],
    content: [
      {
        margin: [0, 12],
        table: {
          alignment: '',
          headerRows: 1,
          widths: ['8%', '18%', '*', '*', '26%', '8%'],
          body: [
            [
              {
                text: 'ลำดับที่',
                fillColor: '#d2d4d2',
                alignment: 'center',
                border: [true, true, true, true],
              },
              {
                text: 'วันดำเนินการ',
                fillColor: '#d2d4d2',
                alignment: 'center',
                border: [true, true, true, true],
              },
              {
                text: 'หมายเลขบิล',
                fillColor: '#d2d4d2',
                alignment: 'center',
                border: [true, true, true, true],
              },

              {
                text: 'คู่ค้า',
                fillColor: '#d2d4d2',
                alignment: 'center',
                border: [true, true, true, true],
              },
              {
                text: 'รายการสินค้า',
                fillColor: '#d2d4d2',
                alignment: 'center',
                border: [true, true, true, true],
              },
              {
                text: 'ราคารวม',
                fillColor: '#d2d4d2',
                alignment: 'center',
                border: [true, true, true, true],
              },
            ],
            ...genItemBody,
          ],
        },
      },

      {},
      {
        // margin: [0, 12],
      },
    ],

    footer: [],

    styles: {
      headerPage: {
        fontSize: 14,
        bold: true,
        alignment: 'center',
      },
      header: {
        fontSize: 11,
        bold: true,
        alignment: 'center',
      },
    },
  };

  pdfMake.createPdf(documentRef).open();
};
export default HistoryPos;
