export const ReportHeaderExport = (setting) => ({
  margin: [20, 4, 20, 0],
  alignment: 'center',
  table: {
    widths: ['*'],
    body: [
      [
        {
          margin: [0, 50, 0, 0],
          text: 'ประวัติการส่งออกสินค้า',
          fontSize: 16,
          bold: true,
          border: [false, false, false, false],
          alignment: 'center',
        },
      ],
      // [
      //   // { text: '', border: [false, false, false, false] },
      //   {
      //     margin: [0, 0, 0, 0],
      //     text: `${setting?.nameEng || ''}\n${
      //       setting?.owner?.address?.house_number || ''
      //     } ${setting?.owner?.address?.village_number || ''} ${
      //       setting?.owner?.address?.road || ''
      //     } ${setting?.owner?.address?.subdistrict || ''}\n${
      //       setting?.owner?.address?.district || ''
      //     } ${setting?.owner?.address?.province || ''} ${
      //       setting?.owner?.address?.postcode || ''
      //     }\nเลขประจำตัวผู้เสียภาษี ${setting?.owner?.tax} โทร ${
      //       setting?.owner?.telephone
      //     } `,
      //     style: 'body1',
      //     border: [false, false, false, false],
      //     alignment: 'center',
      //   },
      // ],
    ],
  },
  styles: {
    header: {
      fontSize: 10,
      bold: true,
      alignment: 'center',
    },
    header1: {
      fontSize: 14,
      bold: true,
    },
  },
  images: {
    logo: setting?.logo?.url || '-',
  },
});

export default ReportHeaderExport;
