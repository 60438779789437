/* eslint-disable react/prop-types */
/* eslint-disable no-return-assign */
import {
  TextField,
  Card,
  FormControlLabel,
  InputAdornment,
  Switch,
} from '@mui/material';
import React from 'react';
import PropTypes from 'prop-types';

import ImageUpload from '../../ImageUpload/ImageUpload';
import PlaceholderLogo from '../../../assets/images/diprom.png';

export function InformationForm({
  errors,
  information,
  Controller,
  control,
  selectedImage,
  setSelectedImage,
  handleChangeCheckDeposit,
  handleChangeCheckBanking,
  handleChangeCheckBanking2,
  checkFreeShipping,
  handleChangeCheckNoTax,
  setCheckFreeShipping,
  handleChangeImageQuotation,
  // handleChangeCheckBanking3,
  handleChangeCheckPromptPay,
  // handleChangeCheckPromptPay2,
  handleChangeCheckPayment2c2p,
  handleChangeCheckShowQRPromptpay,
  handleChangeCheckFreeShipping,
  handleChangeCheckchannelPay,
  handleChangeCheckNoteReceipt,
  channelpay,
  setCheckNoteReceipt,
  checkTopic,
  handleChangeCheckTopic,
  setCheckTopic,
  checkNoteReceipt,
  checkDeposit,
  checkBanking,
  checkBanking2,
  // checkBanking3,
  checkPromptPay,
  checkPayment2c2p,
  ShowQRPromptpay1,
  // checkPromptPay2,
  QRPromptpay1,
  // QRPromptpay2,
  setQRPromptpay1,
  // eslint-disable-next-line no-unused-vars
  setShowQRPromptpay1,
  // setQRPromptpay2,
  taxInclude,
  taxExclude,
  setNoTax,
  noTax,
  handleChangeCheckTaxInclude,
  handleChangeCheckTaxExclude,
  frontweb,
  handleChangeCheckfrontWeb,
  imgQuotation,
  setImgQuotation,
}) {
  console.log('information in from', information);
  console.log('channelpay', channelpay);
  const renderSystemInfo = () => (
    <div className="flex flex-wrap">
      <div className="w-full text-center">โลโก้ระบบ</div>
      <div className="w-full px-1 py-2 flex justify-center my-2 border-2">
        <img src={information?.logo?.url} className="h-48 rounded-md" />
      </div>
      <div className="w-full px-1 py-2">
        <Controller
          name={'name'}
          control={control}
          defaultValue={information ? information.name : ''}
          rules={{ required: true }}
          render={({ field }) => (
            <TextField
              {...field}
              label="ชื่อระบบ"
              fullWidth
              size={'small'}
              multiline={true}
              required
              helperText={errors.name && 'กรุณาใส่ข้อมูล'}
            />
          )}
        />
      </div>
      <div className="w-full px-1 py-2">
        <Controller
          name={'description'}
          control={control}
          defaultValue={information ? information.description : ''}
          render={({ field }) => (
            <TextField
              {...field}
              label="คำอธิบาย"
              fullWidth
              size={'small'}
              helperText={errors.description && 'กรุณาใส่ข้อมูล'}
            />
          )}
        />
      </div>
      <div className="w-full px-1 py-2">
        <Controller
          name={'url'}
          control={control}
          defaultValue={information ? information.url : ''}
          // rules={{ required: true }}
          render={({ field }) => (
            <TextField
              {...field}
              type="url"
              label="URL ของระบบ"
              fullWidth
              size={'small'}
              required
              helperText={errors.url && 'กรุณาใส่ข้อมูล'}
            />
          )}
        />
      </div>
      <div className="w-full px-1 py-2">
        <Controller
          name={'website_url'}
          control={control}
          defaultValue={information ? information.website_url : ''}
          rules={{ required: false }}
          render={({ field }) => (
            <TextField
              {...field}
              type="url"
              label="URL ของเว็ปไซต์"
              fullWidth
              size={'small'}
              helperText={errors.url && 'กรุณาใส่ข้อมูล'}
            />
          )}
        />
      </div>
      <div className="w-full px-1 py-2">
        <Controller
          name={'sponsor.name'}
          control={control}
          defaultValue={information ? information?.sponsor?.name : ''}
          rules={{ required: false }}
          render={({ field }) => (
            <TextField
              {...field}
              label="ชื่อผู้สนับสนุนระบบ"
              fullWidth
              size={'small'}
              multiline={true}
              helperText={errors?.sponsor?.name && 'กรุณาใส่ข้อมูล'}
            />
          )}
        />
      </div>
      <div className="w-full px-1 py-2">
        <Controller
          name={'sponsor.website'}
          control={control}
          defaultValue={information ? information?.sponsor?.website : ''}
          rules={{ required: false }}
          render={({ field }) => (
            <TextField
              {...field}
              label="เว็บไซต์ผู้สนับสนุนระบบ"
              fullWidth
              size={'small'}
              multiline={true}
              helperText={errors?.sponsor?.website && 'กรุณาใส่ข้อมูล'}
            />
          )}
        />
      </div>
      <div className="w-full px-1 py-2 justify-center">
        <ImageUpload
          title={selectedImage[0] ? 'แก้ไขรูปภาพ' : 'อัพโหลดรูปภาพ'}
          images={selectedImage}
          maxNumber={1}
          previewSize={400}
          setImages={setSelectedImage}
          profile={1}
        />
      </div>
    </div>
  );

  const renderTaxInfo = () => (
    <div className="flex flex-wrap pt-2">
      <div className="w-full py-2">
        <Controller
          name={'tax_number'}
          control={control}
          defaultValue={information ? information.tax_number : 7}
          render={({ field }) => (
            <TextField
              {...field}
              label="VAT %"
              fullWidth
              size={'small'}
              type="number"
            />
          )}
        />
      </div>
      <div>
        <FormControlLabel
          control={
            <Switch
              checked={taxInclude}
              onChange={handleChangeCheckTaxInclude}
              // disabled={taxExclude}
            />
          }
          label="สินค้ารวมภาษีมูลค่าเพิ่มแล้ว"
        />
      </div>

      <div>
        <FormControlLabel
          control={
            <Switch
              checked={taxExclude}
              onChange={handleChangeCheckTaxExclude}
              // disabled={taxInclude}
            />
          }
          label="สินค้ายังไม่รวมภาษีมูลค่าเพิ่ม"
        />
      </div>
      <div>
        <FormControlLabel
          control={
            <Switch
              checked={noTax}
              onChange={handleChangeCheckNoTax}
              // disabled={taxInclude}
            />
          }
          label="สินค้าไม่คิดภาษี"
        />
      </div>
    </div>
  );
  const renderFrontWeb = () => (
    <div className="flex flex-wrap pt-2">
      <Controller
        name={'frontweb.url'}
        control={control}
        defaultValue={information ? information?.frontweb?.url : ''}
        render={({ field }) => (
          <TextField
            {...field}
            label="URLเว็ปไซต์"
            fullWidth
            size={'small'}
            multiline={true}
          />
        )}
      />

      <div>
        <FormControlLabel
          control={
            <Switch checked={frontweb} onChange={handleChangeCheckfrontWeb} />
          }
          label="แสดงหน้าเว็ปไซต์บริษัท"
        />
      </div>
    </div>
  );
  // const renderImageQuotation = () => (
  //   <div>
  //     <div className="w-full">
  //       <ImageUpload
  //         profile={1}
  //         preview_size="250"
  //         maxNumber={1}
  //         images={imgQuotation}
  //         setImages={setImgQuotation}
  //         title={'อัพโหลดรูปภาพ'}
  //       />
  //     </div>
  //   </div>
  // );

  const renderChannelPay = () => (
    <div>
      <div className="flex flex-wrap pt-2">
        <div className="w-full px-1 py-2">
          <Controller
            name={'channelpayment.namepay'}
            control={control}
            defaultValue={
              information ? information?.channelpayment?.namepay : ''
            }
            render={({ field }) => (
              <TextField
                {...field}
                label="วิธีการชำระเงินอื่นๆ"
                fullWidth
                size={'small'}
                multiline={true}
              />
            )}
          />
        </div>
        <div className="w-full px-1 py-2">
          <Controller
            name={'channelpayment.namepayeng'}
            control={control}
            defaultValue={
              information ? information?.channelpayment?.namepayeng : ''
            }
            // rules={{ required: true }}
            render={({ field }) => (
              <TextField
                {...field}
                label="วิธีการชำระเงินอื่นๆ(ภาษาอังกฤษ)"
                fullWidth
                size={'small'}
                multiline={true}
              />
            )}
          />
        </div>

        <div>
          <FormControlLabel
            control={
              <Switch
                checked={channelpay}
                onChange={handleChangeCheckchannelPay}
              />
            }
            label="วิธีการชำระเงินอื่นๆ"
          />
        </div>
      </div>
    </div>
  );

  const renderShippingFree = () => (
    <div>
      <div>
        <FormControlLabel
          control={
            <Switch
              checked={checkFreeShipping}
              onChange={handleChangeCheckFreeShipping}
            />
          }
          label="จัดส่งฟรี"
        />
      </div>
      <div className="w-full px-1 py-2">
        <Controller
          name={'shipping_limit'}
          control={control}
          defaultValue={
            information ? information?.shipping?.shipping_limit : ''
          }
          render={({ field }) => (
            <TextField
              {...field}
              label="ราคาขั้นต่ำเมื่อจัดส่งฟรี(บาท)"
              fullWidth
              size={'small'}
              multiline={true}
            />
          )}
        />
      </div>
    </div>
  );
  console.log('checkFreeShipping', checkFreeShipping);

  const renderCompanyInfo = () => (
    <div className="flex flex-row flex-wrap">
      <div className="w-full lg:w-1/2 px-1 py-2">
        <Controller
          name={'owner.name'}
          control={control}
          defaultValue={information ? information.owner?.name : ''}
          rules={{ required: false }}
          render={({ field }) => (
            <TextField
              {...field}
              label="ชื่อบริษัท / หน่วยงาน เจ้าของระบบ"
              fullWidth
              size={'small'}
              multiline={true}
              helperText={errors?.owner?.name && 'กรุณาใส่ข้อมูล'}
            />
          )}
        />
      </div>
      <div className="w-full lg:w-1/2 px-1 py-2">
        <Controller
          name={'owner.website'}
          control={control}
          defaultValue={information ? information.owner?.website : ''}
          rules={{ required: false }}
          render={({ field }) => (
            <TextField
              {...field}
              label="เว็บไซต์เจ้าของระบบ"
              fullWidth
              size={'small'}
              multiline={true}
              helperText={errors?.owner?.website && 'กรุณาใส่ข้อมูล'}
            />
          )}
        />
      </div>
      <div className="w-full lg:w-1/2 px-1 py-2">
        <Controller
          name={'owner.telephone'}
          control={control}
          defaultValue={information ? information.owner?.telephone : ''}
          rules={{ required: false }}
          render={({ field }) => (
            <TextField
              {...field}
              label="เบอร์โทรศัพท์"
              fullWidth
              size={'small'}
              multiline={true}
              helperText={errors?.owner?.telephone && 'กรุณาใส่ข้อมูล'}
            />
          )}
        />
      </div>
      <div className="w-full lg:w-1/2 px-1 py-2">
        <Controller
          name={'owner.email'}
          control={control}
          defaultValue={information ? information.owner?.email : ''}
          rules={{ required: false }}
          render={({ field }) => (
            <TextField
              {...field}
              label="อีเมล"
              fullWidth
              size={'small'}
              multiline={true}
              helperText={errors?.owner?.email && 'กรุณาใส่ข้อมูล'}
            />
          )}
        />
      </div>
      <div className="w-full  px-1 py-2">
        <Controller
          name={'owner.tax'}
          control={control}
          defaultValue={information ? information.owner?.tax : ''}
          rules={{ required: false }}
          render={({ field }) => (
            <TextField
              {...field}
              label="เลขที่ผู้เสียภาษี"
              fullWidth
              size={'small'}
              multiline={true}
              helperText={errors?.owner?.tax && 'กรุณาใส่ข้อมูล'}
            />
          )}
        />
      </div>
      <div className="w-full lg:w-1/2 lg:flex">
        <div className="w-full lg:w-1/2 px-1 py-2">
          <Controller
            name={'owner.address.house_number'}
            control={control}
            defaultValue={
              information ? information.owner?.address?.house_number : ''
            }
            rules={{ required: false }}
            render={({ field }) => (
              <TextField
                {...field}
                label="เลขที่"
                fullWidth
                size={'small'}
                multiline={true}
                helperText={
                  errors?.owner?.address?.house_number && 'กรุณาใส่ข้อมูล'
                }
              />
            )}
          />
        </div>
        <div className="w-full lg:w-1/2 px-1 py-2">
          <Controller
            name={'owner.address.soi'}
            control={control}
            defaultValue={information ? information.owner?.address?.soi : ''}
            rules={{ required: false }}
            render={({ field }) => (
              <TextField
                {...field}
                label="ซอย"
                fullWidth
                size={'small'}
                multiline={true}
                helperText={errors?.owner?.address?.soi && 'กรุณาใส่ข้อมูล'}
              />
            )}
          />
        </div>
      </div>
      <div className="w-full lg:w-1/2 px-1 py-2">
        <Controller
          name={'owner.address.village_number'}
          control={control}
          defaultValue={
            information ? information.owner?.address?.village_number : ''
          }
          rules={{ required: false }}
          render={({ field }) => (
            <TextField
              {...field}
              label="หมู่ที่"
              fullWidth
              size={'small'}
              multiline={true}
              helperText={
                errors?.owner?.address?.village_number && 'กรุณาใส่ข้อมูล'
              }
            />
          )}
        />
      </div>
      <div className="w-full lg:w-1/2 px-1 py-2">
        <Controller
          name={'owner.address.road'}
          control={control}
          defaultValue={information ? information.owner?.address?.road : ''}
          rules={{ required: false }}
          render={({ field }) => (
            <TextField
              {...field}
              label="ถนน"
              fullWidth
              size={'small'}
              multiline={true}
              helperText={errors?.owner?.address?.road && 'กรุณาใส่ข้อมูล'}
            />
          )}
        />
      </div>
      <div className="w-full lg:w-1/2 px-1 py-2">
        <Controller
          name={'owner.address.subdistrict'}
          control={control}
          defaultValue={
            information ? information.owner?.address?.subdistrict : ''
          }
          rules={{ required: false }}
          render={({ field }) => (
            <TextField
              {...field}
              label="ตำบล"
              fullWidth
              size={'small'}
              multiline={true}
              helperText={
                errors?.owner?.address?.subdistrict && 'กรุณาใส่ข้อมูล'
              }
            />
          )}
        />
      </div>
      <div className="w-full lg:w-1/2 px-1 py-2">
        <Controller
          name={'owner.address.district'}
          control={control}
          defaultValue={information ? information.owner?.address?.district : ''}
          rules={{ required: false }}
          render={({ field }) => (
            <TextField
              {...field}
              label="อำเภอ"
              fullWidth
              size={'small'}
              multiline={true}
              helperText={errors?.owner?.address?.district && 'กรุณาใส่ข้อมูล'}
            />
          )}
        />
      </div>
      <div className="w-full lg:w-1/2 px-1 py-2">
        <Controller
          name={'owner.address.province'}
          control={control}
          defaultValue={information ? information.owner?.address?.province : ''}
          rules={{ required: false }}
          render={({ field }) => (
            <TextField
              {...field}
              label="จังหวัด"
              fullWidth
              size={'small'}
              multiline={true}
              helperText={errors?.owner?.address?.province && 'กรุณาใส่ข้อมูล'}
            />
          )}
        />
      </div>
      <div className="w-full lg:w-1/2 px-1 py-2">
        <Controller
          name={'owner.address.postcode'}
          control={control}
          defaultValue={information ? information.owner?.address?.postcode : ''}
          rules={{ required: false }}
          render={({ field }) => (
            <TextField
              {...field}
              label="รหัสไปรษณีย์"
              fullWidth
              size={'small'}
              multiline={true}
              helperText={errors?.owner?.address?.postcode && 'กรุณาใส่ข้อมูล'}
            />
          )}
        />
      </div>
      <div className="w-full lg:w-1/2 px-1 py-2">
        <Controller
          name={'owner.address.country'}
          control={control}
          defaultValue={information ? information.owner?.address?.country : ''}
          rules={{ required: false }}
          render={({ field }) => (
            <TextField
              {...field}
              label="ประเทศ"
              fullWidth
              size={'small'}
              multiline={true}
              helperText={errors?.owner?.address?.country && 'กรุณาใส่ข้อมูล'}
            />
          )}
        />
      </div>
      <div className="w-full px-1 py-2">
        <Controller
          name={'available_time'}
          control={control}
          defaultValue={information ? information?.available_time : ''}
          rules={{ required: false }}
          render={({ field }) => (
            <TextField
              {...field}
              label="เวลาทำการ"
              fullWidth
              size={'small'}
              multiline={true}
            />
          )}
        />
      </div>
    </div>
  );

  const renderBankingForm = () => (
    <>
      <div>
        <FormControlLabel
          control={
            <Switch
              checked={checkBanking}
              onChange={handleChangeCheckBanking}
            />
          }
          label="บัญชีธนาคาร1"
        />
      </div>
      <div className="flex flex-row flex-wrap">
        <div className="w-full px-1 py-2">
          <Controller
            name={'accountNameBank1'}
            control={control}
            defaultValue={information ? information.banking?.accountName : ''}
            rules={{ required: checkBanking }}
            render={({ field }) => (
              <TextField
                {...field}
                label="ชื่อบัญชี"
                fullWidth
                size={'small'}
                multiline={true}
                required={checkBanking}
                disabled={!checkBanking}
              />
            )}
          />
        </div>
        <div className="w-full lg:w-1/2 px-1 py-2">
          <Controller
            name={'bankName'}
            control={control}
            defaultValue={information ? information.banking?.bankName : ''}
            rules={{ required: checkBanking }}
            render={({ field }) => (
              <TextField
                {...field}
                label="ชื่อธนาคาร"
                fullWidth
                size={'small'}
                multiline={true}
                required={checkBanking}
                disabled={!checkBanking}
              />
            )}
          />
        </div>
        <div className="w-full lg:w-1/2 px-1 py-2">
          <Controller
            name={'accountNumberฺBank1'}
            control={control}
            defaultValue={information ? information.banking?.accountNumber : ''}
            rules={{ required: checkBanking }}
            render={({ field }) => (
              <TextField
                {...field}
                label="เลขบัญชี"
                fullWidth
                size={'small'}
                multiline={true}
                required={checkBanking}
                disabled={!checkBanking}
              />
            )}
          />
        </div>
        <FormControlLabel
          control={
            <Switch
              checked={ShowQRPromptpay1}
              onChange={handleChangeCheckShowQRPromptpay}
            />
          }
          label="แสดงรูปQR"
        />
        {ShowQRPromptpay1 && (
          <div className="w-full">
            <ImageUpload
              profile={1}
              preview_size="250"
              maxNumber={1}
              images={QRPromptpay1}
              setImages={setQRPromptpay1}
              title={QRPromptpay1[0] ? 'แก้ไขรูปภาพ' : 'อัพโหลดรูปภาพ'}
            />
          </div>
        )}
      </div>
      <div>
        <FormControlLabel
          control={
            <Switch
              checked={checkBanking2}
              onChange={handleChangeCheckBanking2}
            />
          }
          label="บัญชีธนาคาร2"
        />
      </div>
      <div className="flex flex-row flex-wrap">
        <div className="w-full px-1 py-2">
          <Controller
            name={'accountNameBank2'}
            control={control}
            defaultValue={information ? information.banking2?.accountName : ''}
            rules={{ required: checkBanking2 }}
            render={({ field }) => (
              <TextField
                {...field}
                label="ชื่อบัญชี"
                fullWidth
                size={'small'}
                multiline={true}
                required={checkBanking2}
                disabled={!checkBanking2}
              />
            )}
          />
        </div>
        <div className="w-full lg:w-1/2 px-1 py-2">
          <Controller
            name={'bankName2'}
            control={control}
            defaultValue={information ? information.banking2?.bankName : ''}
            rules={{ required: checkBanking2 }}
            render={({ field }) => (
              <TextField
                {...field}
                label="ชื่อธนาคาร"
                fullWidth
                size={'small'}
                multiline={true}
                required={checkBanking2}
                disabled={!checkBanking2}
              />
            )}
          />
        </div>
        <div className="w-full lg:w-1/2 px-1 py-2">
          <Controller
            name={'accountNumberBank2'}
            control={control}
            defaultValue={
              information ? information.banking2?.accountNumber : ''
            }
            rules={{ required: checkBanking2 }}
            render={({ field }) => (
              <TextField
                {...field}
                label="เลขบัญชี"
                fullWidth
                size={'small'}
                multiline={true}
                required={checkBanking2}
                disabled={!checkBanking2}
              />
            )}
          />
        </div>
      </div>
      {/* <div>
        <FormControlLabel
          control={
            <Switch
              checked={checkBanking3}
              onChange={handleChangeCheckBanking3}
            />
          }
          label="บัญชีธนาคาร3"
        />
      </div>
      <div className="flex flex-row flex-wrap">
        <div className="w-full px-1 py-2">
          <Controller
            name={'accountNameBank3'}
            control={control}
            defaultValue={information ? information.banking3?.accountName : ''}
            rules={{ required: checkBanking3 }}
            render={({ field }) => (
              <TextField
                {...field}
                label="ชื่อบัญชี"
                fullWidth
                size={'small'}
                multiline={true}
                required={checkBanking3}
                disabled={!checkBanking3}
              />
            )}
          />
        </div>
        <div className="w-full lg:w-1/2 px-1 py-2">
          <Controller
            name={'bankName3'}
            control={control}
            defaultValue={information ? information.banking3?.bankName : ''}
            rules={{ required: checkBanking3 }}
            render={({ field }) => (
              <TextField
                {...field}
                label="ชื่อธนาคาร"
                fullWidth
                size={'small'}
                multiline={true}
                required={checkBanking3}
                disabled={!checkBanking3}
              />
            )}
          />
        </div>
        <div className="w-full lg:w-1/2 px-1 py-2">
          <Controller
            name={'accountNumberBank3'}
            control={control}
            defaultValue={
              information ? information.banking3?.accountNumber : ''
            }
            rules={{ required: checkBanking3 }}
            render={({ field }) => (
              <TextField
                {...field}
                label="เลขบัญชี"
                fullWidth
                size={'small'}
                multiline={true}
                required={checkBanking3}
                disabled={!checkBanking3}
              />
            )}
          />
        </div>
      </div> */}
    </>
  );

  const renderPromptpayForm = () => (
    <>
      <div>
        <FormControlLabel
          control={
            <Switch
              checked={checkPromptPay}
              onChange={handleChangeCheckPromptPay}
            />
          }
          label="พร้อมเพย์"
        />
      </div>
      <div className="flex flex-row flex-wrap">
        <div className="w-full px-1 py-2">
          <Controller
            name={'accountName'}
            control={control}
            defaultValue={information ? information.promptpay?.accountName : ''}
            rules={{ required: checkPromptPay }}
            render={({ field }) => (
              <TextField
                {...field}
                label="ชื่อบัญชี"
                fullWidth
                size={'small'}
                multiline={true}
                required={checkPromptPay}
                disabled={!checkPromptPay}
              />
            )}
          />
        </div>
        <div className="w-full px-1 py-2">
          <Controller
            name={'accountNumber'}
            control={control}
            defaultValue={
              information ? information.promptpay?.accountNumber : ''
            }
            rules={{ required: checkPromptPay }}
            render={({ field }) => (
              <TextField
                {...field}
                label="หมายเลขพร้อมเพย์"
                fullWidth
                size={'small'}
                multiline={true}
                required={checkPromptPay}
                disabled={!checkPromptPay}
              />
            )}
          />
        </div>
        <div className="text-red-500">
          * ใช้สำหรับสร้างคิวอาร์โค๊ดการชำระเงิน
        </div>
      </div>
      {/* <div>
        <FormControlLabel
          control={
            <Switch
              checked={checkPromptPay2}
              onChange={handleChangeCheckPromptPay2}
            />
          }
          label="พร้อมเพย์2"
        />
      </div>
      <div className="flex flex-row flex-wrap">
        <div className="w-full px-1 py-2">
          <Controller
            name={'accountName2'}
            control={control}
            defaultValue={
              information ? information.promptpay2?.accountName : ''
            }
            rules={{ required: checkPromptPay }}
            render={({ field }) => (
              <TextField
                {...field}
                label="ชื่อบัญชี"
                fullWidth
                size={'small'}
                multiline={true}
                required={checkPromptPay2}
                disabled={!checkPromptPay2}
              />
            )}
          />
        </div>
        <div className="w-full px-1 py-2">
          <Controller
            name={'accountNumber2'}
            control={control}
            defaultValue={
              information ? information.promptpay2?.accountNumber : ''
            }
            rules={{ required: checkPromptPay2 }}
            render={({ field }) => (
              <TextField
                {...field}
                label="หมายเลขพร้อมเพย์"
                fullWidth
                size={'small'}
                multiline={true}
                required={checkPromptPay2}
                disabled={!checkPromptPay2}
              />
            )}
          />
        </div>
        <div className="text-red-500">
          * ใช้สำหรับสร้างคิวอาร์โค๊ดการชำระเงิน
        </div>
      </div> */}
    </>
  );
  const renderpayment2c2p = () => (
    <div>
      <div>
        <FormControlLabel
          control={
            <Switch
              defaultChecked={checkPayment2c2p}
              checked={checkPayment2c2p}
              onChange={handleChangeCheckPayment2c2p}
            />
          }
          label="บัตรเครดิต"
        />
      </div>
      <div className="w-full px-1 py-2">
        <Controller
          name={'invoiceNo'}
          control={control}
          defaultValue={information ? information?.payment2c2p?.invoiceNo : ''}
          rules={{ required: checkPayment2c2p }}
          render={({ field }) => (
            <TextField
              {...field}
              label="หมายเลขใบแจ้งหนี้"
              fullWidth
              size={'small'}
              multiline={true}
              disabled={!checkPayment2c2p}
              required={checkPayment2c2p}
            />
          )}
        />
      </div>
    </div>
  );

  const renderDepositForm = () => (
    <>
      <div>
        <FormControlLabel
          control={
            <Switch
              checked={checkDeposit}
              onChange={handleChangeCheckDeposit}
            />
          }
          label="การมัดจำ"
        />
      </div>

      <div className="w-full px-1 py-2">
        <Controller
          name={'percent'}
          control={control}
          defaultValue={information ? information?.deposit?.percent : ''}
          rules={{ required: checkDeposit }}
          render={({ field }) => (
            <TextField
              {...field}
              label="เปอร์เซ็นต์การมัดจำ"
              fullWidth
              size={'small'}
              multiline={true}
              disabled={!checkDeposit}
              required={checkDeposit}
              InputProps={{
                endAdornment: <InputAdornment position="end">%</InputAdornment>,
              }}
            />
          )}
        />
      </div>
    </>
  );

  const renderNoteReceipt = () => (
    <>
      <div>
        <FormControlLabel
          control={
            <Switch
              checked={checkNoteReceipt}
              onChange={handleChangeCheckNoteReceipt}
            />
          }
          label="หมายเหตุใบเสร็จรับเงิน"
        />
      </div>
      <div className="w-full px-1 py-2">
        <Controller
          name={'note'}
          control={control}
          defaultValue={information ? information?.noteReceipt?.note : ''}
          rules={{ required: checkNoteReceipt }}
          render={({ field }) => (
            <TextField
              {...field}
              label="หมายเหตุ"
              fullWidth
              size={'small'}
              multiline={true}
              disabled={!checkNoteReceipt}
              required={checkNoteReceipt}
            />
          )}
        />
      </div>
    </>
  );
  const renderTopicReceipt = () => (
    <>
      <div>
        <FormControlLabel
          control={
            <Switch checked={checkTopic} onChange={handleChangeCheckTopic} />
          }
          label="หัวเรื่องใบเสร็จ/ใบกำกับภาษี"
        />
      </div>
    </>
  );

  return (
    <div>
      <div className="flex flex-wrap">
        <div className="w-full lg:w-4/12 px-2 mb-2">
          <Card className="p-4">
            <div className="font-semibold ">ข้อมูลระบบ</div>
            {renderSystemInfo()}
          </Card>
          <Card className="p-4 mt-4">
            <div className="font-semibold ">การจดทะเบียนภาษีมูลค่าเพิ่ม</div>
            {renderTaxInfo()}
          </Card>
          <Card className="p-4 mt-4">
            <div className="font-semibold ">การแสดงหน้าเว็ปไซต์</div>
            {renderFrontWeb()}
          </Card>
          <Card className="p-4 mt-4">
            <div className="font-semibold ">วิธีการชำระเงินเพิ่มเติม</div>
            {renderChannelPay()}
          </Card>
          <Card className="p-4 mt-4">
            <div className="font-semibold "></div>
            {renderChannelPay()}
          </Card>
          {/* <Card className="p-4 mt-4">
            <div className="font-semibold ">ใบเสนอราคา</div>
            {renderImageQuotation()}
          </Card> */}
          {/* <Card className="p-4 mt-4">
            <div className="font-semibold ">ค่าจัดส่ง</div>
            {renderShippingFree()}
          </Card> */}
        </div>

        <div className="w-full lg:w-8/12 px-2 ">
          <div>
            <Card className="p-4">
              <div className="font-semibold mb-2">ข้อมูลบริษัท</div>
              {renderCompanyInfo()}
            </Card>
          </div>
          <div className="pt-4">
            <Card className="p-4">
              <div className="font-semibold mb-2">ข้อมูลการชำระเงิน</div>
              {renderBankingForm()}
              {renderPromptpayForm()}
              {renderpayment2c2p()}
              {renderDepositForm()}
              <div className="font-semibold mb-2 pt-2">เอกสารการเงิน</div>
              {renderNoteReceipt()}
              {renderTopicReceipt()}
            </Card>
          </div>
        </div>
      </div>
    </div>
  );
}

InformationForm.propTypes = {
  errors: PropTypes.shape({
    name: PropTypes.object,
    description: PropTypes.object,
    owner: PropTypes.object,
    sponsor: PropTypes.object,
    url: PropTypes.object,
    payment: PropTypes.object,
    banking: PropTypes.object,
    promptpay: PropTypes.object,
    imgQuotation: PropTypes.object,
  }),
  information: PropTypes.object,
  Controller: PropTypes.func,
  control: PropTypes.object,
  selectedImage: PropTypes.arrayOf(PropTypes.object),
  setSelectedImage: PropTypes.node.isRequired,
  handleChangeCheckDeposit: PropTypes.func,
  handleChangeCheckShowQRPromptpray: PropTypes.func,
  handleChangeCheckBanking: PropTypes.func,
  handleChangeCheckBanking2: PropTypes.func,
  handleChangeCheckBanking3: PropTypes.func,
  handleChangeCheckPromptPay: PropTypes.func,
  handleChangeCheckPromptPay2: PropTypes.func,
  handleChangeCheckPayment2c2p: PropTypes.func,
  handleChangeCheckNoteReceipt: PropTypes.func,
  handleChangeImageQuotation: PropTypes.func,
  handleChangeCheckfrontWeb: PropTypes.func,
  handleChangeCheckchannelPay: PropTypes.func,
  handleChangeCheckTopic: PropTypes.func,
  checkDeposit: PropTypes.bool,
  checkBanking: PropTypes.bool,
  checkBanking2: PropTypes.bool,
  checkBanking3: PropTypes.bool,
  checkPromptPay: PropTypes.bool,
  checkPromptPay2: PropTypes.bool,
  checkPayment2c2p: PropTypes.bool,
  checkNoteReceipt: PropTypes.bool,
  checkTopic: PropTypes.bool,
  setQRPromptpay1: PropTypes.func,
  setQRPromptpay2: PropTypes.func,
  setImgQuotation: PropTypes.func,
  ShowQRPromptpay1: PropTypes.bool,
  setShowQRPromptpay1: PropTypes.func,
  setCheckNoteReceipt: PropTypes.func,
  setCheckTopic: PropTypes.func,
  QRPromptpay1: PropTypes.array,
  QRPromptpay2: PropTypes.array,
  handleChangeCheckTaxInclude: PropTypes.func,
  handleChangeCheckTaxExclude: PropTypes.func,
  taxInclude: PropTypes.bool,
  taxExclude: PropTypes.bool,

  noTax: PropTypes.bool,
  channelpay: PropTypes.bool,
  frontweb: PropTypes.bool,
};

InformationForm.defaultProps = {
  information: null,
};

export default InformationForm;
