/* eslint-disable no-underscore-dangle */
/* eslint-disable import/order */
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  Button,
  Card,
  TextField,
  InputAdornment,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Paper,
  Fade,
  Modal,
  Backdrop,
  FormControlLabel,
  Checkbox,
} from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { useHistory, useParams } from 'react-router';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import { app as appConfig } from '../../configs';
import SaveIcon from '@mui/icons-material/Save';
import TaskAltIcon from '@mui/icons-material/TaskAlt';

import * as actions from '../../redux/actions';
import { BackButton } from '../../components/Button';
import { CSVUpload } from '../../components/CSVUpload';
import Loading from '../../components/Loading';
import { Error } from '../../components/Error';
import { ViewTitle } from '../../components/ViewTitle';
import ButtonHistory from '../../components/Button/ButtonHistory';
import { ExportExcelContainer } from '../../components/ExcelDownload';
import { ProductUtil, ProductAllDataUtil } from '../../utils/modelutils';
import { useForm, Controller } from 'react-hook-form';
import dayjs from 'dayjs';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '90vw',
  boxShadow: 24,
  p: 4,
};
const defaultValue = {
  name: '',
  unit: '',
  packing_size: '',
  packing_unit: '',
  net: '',
  unit_net: '',
  detail: '',
  type_code: '',
  inventory: '',
  images: '',
  option: '',
};

function DetailProductStock({ title, subtitle }) {
  const dispatch = useDispatch();
  const warehouse = useSelector((state) => state.warehouse);
  const product = useSelector((state) => state.product);
  const employee = useSelector((state) => state.employee);
  console.log('Employee', employee);
  const me = useSelector((state) => state.me);
  const { id } = useParams();
  const [searchTerm, setSearchTerm] = useState('');
  const stockChecking = useSelector((state) => state.stockChecking);
  const [name, setName] = useState('');
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const [total, setTotal] = useState(undefined);
  const [csvUploadOpen, setCsvUploadOpen] = useState(false);
  const [csvUploadStock, setCsvUploadStock] = useState(false);
  const [csvData, setCsvData] = useState([]);
  const [stockData, setStockData] = useState([]);
  const [defaultMe, setDefaultMe] = useState(me?.userData);
  const [checkStock, setCheckStock] = useState(false);
  const [openCheckStock, setOpenCheckStock] = useState(false);
  const [dataStock, setDataStock] = useState();
  const history = useHistory();

  useEffect(() => {
    dispatch(actions.productReset());

    return () => {};
  }, []);
  console.log(' defaultMe', defaultMe);

  useEffect(() => {
    dispatch(actions.warehouseGet(id));
    dispatch(
      actions.productAll({
        name,
        page,
        size,
        warehouse: id,
      }),
    );
    return () => {};
  }, [name, page, size]);

  useEffect(() => {
    if (product?.total) {
      setTotal(product?.total);
    }
    return () => {};
  }, [product]);
  const handleChangeStock = (event) => {
    setCheckStock(event.target.checked);
  };

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      setName(searchTerm);
      setPage(1);
    }, 700);
    return () => clearTimeout(delayDebounceFn);
  }, [searchTerm]);

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  const handlePushToDetailProductStock = async (productId) => {
    history.push(`/wms/product/${productId}`);
  };
  const handleCloseCheckStock = () => setOpenCheckStock(false);
  const handleCSVOpen = () => setCsvUploadOpen(true);
  const handleUploadStock = () => setCsvUploadStock(true);
  const handleCSVClose = () => {
    setCsvUploadOpen(false);
    setCsvData([]);
  };
  const handleUploadStockClose = () => {
    setCsvUploadStock(false);
    setDataStock([]);
  };
  const handleChangeRowsPerPage = (event) => {
    setSize(event.target.value);
    setPage(1);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage + 1);
  };
  const {
    handleSubmit: checkStockHandleSubmit,
    control: checkStockControl,
    reset: checkStockReset,
  } = useForm();

  const handleOpenCheckStock = () => setOpenCheckStock(true);
  const handleAddFromFile = async () => {
    if (!_.isEmpty(csvData)) {
      console.log('csvData', csvData);
      const NewCSVData = _.map(csvData, (eachCSV) => {
        const payload = {
          ...eachCSV,
          product_transaction_type: 'หน้าร้าน',
          employee: defaultMe?._id,
          warehouse: id,
          price:
            eachCSV?.price === ''
              ? 0
              : parseFloat(eachCSV?.price.replaceAll(',', '')),
          inventory:
            eachCSV?.inventory === ''
              ? 0
              : parseFloat(eachCSV?.inventory.replaceAll(',', '')),
        };
        return payload;
      });
      // const NewDataStock = _.map(NewCSVData, (eachStock) => {
      //   return NewDataStock;
      // });
      // console.log('eachStock', eachStock);

      console.log('NewCSVData', NewCSVData);
      try {
        await dispatch(
          actions.productTransactionCreate({
            product_transaction_type: 'หน้าร้าน',
            employee: defaultMe?._id,
            warehouse: id,
            employee_id: defaultMe?._id,
            arr: NewCSVData,
            remark: 'ตัดสต็อกผ่านการอัพโหลดไฟล์ CSV',
          }),
        );
        alert('สำเร็จ');
        handleCSVClose();
        await dispatch(
          actions.productAll({
            page,
            size,
            name,
            warehouse: id,
          }),
        );
      } catch (error) {
        alert(error?.message);
        console.error(error);
      }
    } else {
      alert('ไม่สามารถอ่านไฟล์รายการลูกค้าได้');
    }
  };
  const handleEditStock = async () => {
    if (!_.isEmpty(stockData)) {
      console.log('stockData in Producr', stockData);
      const NewCSVData = _.map(stockData, (eachCSV) => {
        const payload = {
          ...eachCSV,
          product_transaction_type: 'อัพเดทสต็อก',
          employee: defaultMe?._id,
          warehouse: id,
          inventory:
            eachCSV?.inventory === ''
              ? 0
              : parseFloat(eachCSV?.inventory.replaceAll(',', '')),
        };
        console.log('payload', payload);
        return payload;
      });
      console.log('NewCSVData', NewCSVData);
      try {
        await dispatch(
          actions.productTransactionCreate({
            product_transaction_type: 'อัพเดทสต็อก',
            employee: defaultMe?._id,
            warehouse: id,
            employee_id: defaultMe?._id,
            arr: NewCSVData,
            remark: 'อัพเดทสต๊อกทุกเดือน',
          }),
        );
        alert('สำเร็จ');
        handleUploadStockClose();
        await dispatch(
          actions.productAll({
            page,
            size,
            name,
            warehouse: id,
          }),
        );
      } catch (error) {
        alert(error?.message);
        console.error(error);
      }
    } else {
      alert('ไม่สามารถอ่านไฟล์รายการลูกค้าได้');
    }
  };
  const onSubmitCheckStock = async (data) => {
    console.log('data in check stock', data);
    const payload = {
      ...data,
      inventory: parseFloat(data.inventory),
      product: dataStock?.id,
      employee: me?.userData?.id || me?.userData?._id,
    };

    console.log('payload in check stock', payload);

    await dispatch(actions.createOneStockChecking(payload));
    checkStockReset(defaultValue);
    dispatch(
      actions.getAllStockChecking({
        name,
        page,
        size: 1000,
        productId: dataStock?.id,
      }),
    );
  };
  const displayCheckStock = () => (
    <div className="w-full">
      <Paper>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }}>
            <TableHead className="bg-yellow-100">
              <TableRow>
                <TableCell>
                  <div className="font-bold">ลำดับที่</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold"> จำนวนที่บันทึก</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold"> เวลาที่บันทึก</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold"> บันทึกโดย</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold"> รายละเอียด</div>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!_.isEmpty(stockChecking?.rows) && (
                <>
                  {stockChecking?.rows.map((eachStock, index) => (
                    <TableRow
                      key={index}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                      className={index % 2 === 0 ? 'bg-gray-100' : ''}
                    >
                      <TableCell>{index + 1}</TableCell>
                      <TableCell>
                        <div className="my-auto">{eachStock?.inventory}</div>
                      </TableCell>
                      <TableCell>
                        <div className="my-auto">
                          {dayjs(eachStock?.date).format('D MMM BBBB')}
                        </div>
                      </TableCell>
                      <TableCell>
                        <div className="my-auto">
                          {eachStock?.employee?.firstname}{' '}
                          {eachStock?.employee?.lastname}
                        </div>
                      </TableCell>
                      <TableCell>
                        <div className="my-auto">
                          {eachStock?.detail || '-'}
                        </div>
                      </TableCell>
                    </TableRow>
                  ))}
                </>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </div>
  );
  const renderModalCheckStock = () => (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={openCheckStock}
      onClose={handleCloseCheckStock}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={openCheckStock}>
        <Card sx={style} className="max-w-3xl my-4">
          <div className="max-h-screen overflow-y-auto">
            <div className="py-2 text-lg">ตรวจสอบสต๊อคสินค้า</div>
            <div className="py-2">
              <form onSubmit={checkStockHandleSubmit(onSubmitCheckStock)}>
                <div className="w-full  px-1 py-2">
                  <Controller
                    name={'inventory'}
                    control={checkStockControl}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        label="จำนวนที่นับได้"
                        fullWidth
                        required
                        type="number"
                        size={'small'}
                      />
                    )}
                  />
                </div>
                <div className="w-full px-1 pb-1">
                  <div className="py-1">วันที่นับสต๊อค</div>
                  <Controller
                    name={'date'}
                    control={checkStockControl}
                    defaultValue={dayjs(new Date())
                      .locale('th')
                      .format('YYYY-MM-DD')}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        type="date"
                        fullWidth
                        required
                        size={'small'}
                      />
                    )}
                  />
                </div>
                <div className="w-full  px-1 py-2">
                  <Controller
                    name={'detail'}
                    control={checkStockControl}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        label="รายละเอียดเพิ่มเติม"
                        fullWidth
                        size={'small'}
                        rows={3}
                        multiline={true}
                      />
                    )}
                  />
                </div>
                <div className="flex flex-row justify-end gap-1 py-4">
                  <Button
                    variant="contained"
                    type="submit"
                    startIcon={<SaveIcon />}
                  >
                    บันทึก
                  </Button>
                  <Button variant="outlined" onClick={handleCloseCheckStock}>
                    ยกเลิก
                  </Button>
                </div>
              </form>
              {displayCheckStock()}
            </div>
          </div>
        </Card>
      </Fade>
    </Modal>
  );
  const renderCSVCheckStock = () => (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={csvUploadStock}
      onClose={handleUploadStockClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={csvUploadStock}>
        <Card sx={style} className="max-w-6xl">
          <div className="max-h-screen overflow-y-auto my-6">
            <div className=" text-xl">อัปโหลดสต็อกปัจจุบัน</div>
            <div className="py-2 font-system">
              <div className="py-2">
                อัพโหลดไฟล์ที่จะปรับสต็อก .csv โดยมีโครงสร้างตามไฟล์เทมเพลต
                แถวที่ 1-2 และแถวสุดท้าย จะไม่ถูกนำมาคิดในระบบ <br />
              </div>
              <div className="font-bold ">
                วิธีการอัปเดทสต๊อก
                <br />
              </div>
              <div>
                1.ดาวโหลดสต็อกคงเหลือในคลัง
                <br />
                2.คัดลอกใส่ในเทมเพลต และปรับยอดในคลัง
                จากนั้นเลือกไฟล์ที่กรอกข้อมูล อัปโหลดที่ปุ่มเลือกไฟล์ =>
                กดบันทึก
                <br />
                3.ตรวจสอบการอัพเดทสต็อก ที่ประวัติ => ประวัติการอัปเดทสต็อก
              </div>

              <div className="flex">
                <div>
                  <Button variant="contained">
                    <Link to="/filetemplate/CheckStock.csv" target="_blank">
                      ดาวน์โหลดเทมเพลตปรับสต็อก
                    </Link>
                  </Button>
                </div>
              </div>
            </div>
            <div className="py-2">
              <div className="flex justify-center">
                <div>
                  <CSVUpload setCsvData={setStockData} />
                  {!_.isEmpty(stockData) && (
                    <div>
                      <p className="font-system my-1">
                        พบข้อมูล {_.size(stockData)} รายการ
                      </p>
                      <Button
                        color="primary"
                        variant="contained"
                        type="button"
                        onClick={handleEditStock}
                      >
                        บันทึก
                      </Button>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </Card>
      </Fade>
    </Modal>
  );
  const renderCSVUploadModal = () => (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      open={csvUploadOpen}
      onClose={handleCSVClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={csvUploadOpen}>
        <Card sx={style} className="max-w-6xl">
          <div className="max-h-screen overflow-y-auto my-6">
            <div className=" text-xl">อัพโหลดข้อมูล</div>
            <div className="py-2 font-system">
              อัพโหลดไฟล์ .csv โดยมีโครงสร้างตามไฟล์เทมเพลต แถวที่ 1-2
              และแถวสุดท้าย จะไม่ถูกนำมาคิดในระบบ <br />
              <div className="flex">
                <div>
                  <Button variant="contained">
                    <Link to="/filetemplate/LotStock.csv" target="_blank">
                      ดาวน์โหลดเทมเพลต
                    </Link>
                  </Button>
                </div>
                <div className="px-2">
                  <Link
                    to={`/wms/product-stock/audit-stock-reduce/${id}`}
                    onClick={() => {
                      handleCSVClose();
                    }}
                  >
                    <Button color="success" variant="contained">
                      ตรวจสอบว่าไฟล์ตัดสต็อกได้หรือไม่
                    </Button>
                  </Link>
                </div>
              </div>
            </div>
            <div className="py-2">
              <div className="flex justify-center">
                <div>
                  <CSVUpload setCsvData={setCsvData} />
                  {!_.isEmpty(csvData) && (
                    <div>
                      <p className="font-system my-1">
                        พบข้อมูล {_.size(csvData)} รายการ
                      </p>
                      <Button
                        color="primary"
                        variant="contained"
                        type="button"
                        onClick={handleAddFromFile}
                      >
                        บันทึก
                      </Button>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </Card>
      </Fade>
    </Modal>
  );

  const renderAddButtom = () => (
    <div className="flex flex-row justify-end pb-4">
      <div>
        <Button
          variant="contained"
          onClick={() => history.push(`/wms/product-stock/create/${id}`)}
        >
          การรับเข้าสินค้า
        </Button>
      </div>
    </div>
  );

  const renderExportButton = () => (
    <div className="flex flex-row justify-end pb-4">
      <div>
        <Button
          variant="contained"
          onClick={() => history.push(`/wms/export-stock/create/${id}`)}
        >
          การส่งออกสินค้า(ลูกค้า)
        </Button>
      </div>
    </div>
  );
  const renderMoveButton = () => (
    <div className="flex flex-row justify-end pb-4">
      <div>
        <Button
          variant="contained"
          onClick={() => history.push(`/wms/material-stock/create/${id}`)}
        >
          การส่งออกสินค้า(บีโบ้)
        </Button>
      </div>
    </div>
  );

  const renderCheckStockButton = () => (
    <div className="flex flex-row justify-end pb-4  text-white">
      <div>
        <Button
          variant="contained"
          onClick={handleUploadStock}
          startIcon={<TaskAltIcon />}
        >
          ตรวจสต๊อก
        </Button>
      </div>
    </div>
  );

  const renderExelButton = () => (
    <div className="flex flex-row justify-end pb-4  text-white">
      <div>
        <Button
          variant="contained"
          onClick={handleCSVOpen}
          startIcon={<FileUploadIcon />}
        >
          ตัดสต๊อก
        </Button>
      </div>
    </div>
  );
  const renderExportHistory = () => (
    <div className="flex flex-row justify-end pb-4">
      <div>
        <ButtonHistory />
      </div>
    </div>
  );

  // const filterProducts = _.filter(
  //   product,(eachProduct) => {
  //     if (product) {

  //     }
  //   }
  // )
  console.log('Product in warehouse', product);

  const renderExportExel = () => (
    <div className="my-auto mr-1">
      <ExportExcelContainer
        columnList={ProductUtil?.columns}
        columnDataList={ProductAllDataUtil?.columns}
        currentData={product?.rows}
        dataAPIEndPoint="product"
        dataQuery={{
          fetchStockLot: false,
          fetchProcess: false,
          page: 1,
          warehouse: id,
          size: appConfig?.maxFetchSize,
        }}
        sheetName="Product"
      />
    </div>
  );

  const renderSearch = () => (
    <Card>
      <div className="p-4 flex flex-row">
        <div className="w-full md:w-1/2">
          <TextField
            label="ค้นหา"
            fullWidth
            size={'small'}
            id="outlined-start-adornment"
            onChange={(e) => {
              setSearchTerm(e.target.value);
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <i className="fas fa-search"></i>
                </InputAdornment>
              ),
            }}
          />
        </div>
      </div>
    </Card>
  );
  const renderTable = () => (
    <div className="my-2">
      <Paper>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }}>
            <TableHead>
              <TableRow>
                <TableCell>
                  <div className="font-bold">ลำดับที่</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold"> ชื่อสินค้า</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold"> รูปภาพ</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold"> จำนวนคงเหลือ</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold"> ราคาทุน/ราคาขาย</div>
                </TableCell>
                <TableCell>
                  <div className="font-bold"> ดำเนินการ</div>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!_.isEmpty(product?.rows) ? (
                product?.rows?.map((row, index) => (
                  <TableRow
                    key={index}
                    sx={{
                      '&:last-child td, &:last-child th': { border: 0 },
                    }}
                  >
                    <TableCell component="th" scope="row">
                      <div className="my-auto">
                        {(page - 1) * size + index + 1}
                      </div>
                    </TableCell>
                    <TableCell>
                      <div className="font-bold text-lg">{`${row?.name}`}</div>
                      <div className="">{`( ${row?.type_code || '-'} )`}</div>
                      <div className="">{`( ${row?.brand?.name || '-'} )`}</div>
                    </TableCell>
                    {_.isEmpty(row.images) ? (
                      <TableCell>
                        <img
                          key={index}
                          src="https://storage.googleapis.com/mydev_eonlineshop/other/thumbnails/1645339249181_800x800.png"
                          width="100"
                          height="100"
                          className="border-2"
                        ></img>
                      </TableCell>
                    ) : (
                      <TableCell>
                        <img
                          key={index}
                          src={`${row?.images[0]?.url}`}
                          width="100"
                          height="100"
                          className="border-2"
                        ></img>
                      </TableCell>
                    )}
                    <TableCell>
                      <div
                        className={`${
                          row?.inventory < row?.minimum_inventory_size
                            ? 'bg-red-400 text-center rounded-xl'
                            : 'bg-green-400 text-center rounded-xl'
                        }`}
                      >
                        {row?.inventory} {row?.unit}
                      </div>
                      {checkStock && (
                        <div className="flex justify-center mt-1">
                          <Button
                            variant="contained"
                            size={'small'}
                            fullWidth
                            onClick={() => {
                              handleOpenCheckStock(row?.id);
                              setDataStock(row);
                            }}
                          >
                            ตรวจสต๊อค
                          </Button>
                        </div>
                      )}
                    </TableCell>
                    {row?.option_multiple === row?.option_type ? (
                      <TableCell>
                        <div className="">
                          {`${parseFloat(
                            row?.cost_price_option_multiple,
                            10 || 0,
                            10,
                          ).toLocaleString(undefined)}`}{' '}
                          {'บาท'}
                        </div>
                        <div className="font-bold">
                          {`${parseFloat(
                            row?.price_option_multiple,
                            10 || 0,
                            10,
                          ).toLocaleString(undefined)}`}{' '}
                          {'บาท'}
                        </div>
                      </TableCell>
                    ) : (
                      <TableCell>
                        <div className="">
                          {`${parseFloat(
                            row?.cost_price,
                            10 || 0,
                            10,
                          ).toLocaleString(undefined)}`}{' '}
                          {'บาท'}
                        </div>
                        <div className="font-bold">
                          {`${parseFloat(
                            row?.price,
                            10 || 0,
                            10,
                          ).toLocaleString(undefined)}`}{' '}
                          {'บาท'}
                        </div>
                      </TableCell>
                    )}
                    <TableCell>
                      {' '}
                      <div className="flex  flex-wrap gap-1">
                        <Button
                          variant="contained"
                          color={'info'}
                          size={'small'}
                          onClick={() => handlePushToDetailProductStock(row.id)}
                        >
                          รายละเอียด
                        </Button>

                        {/* <div>
                          <Button
                            variant="contained"
                            color={'yellow'}
                            size={'small'}
                            onClick={() => {
                              setOpenAddStock(true);
                              setAddStock(row);
                            }}
                            startIcon={<EditIcon />}
                          >
                            เพิ่มสต๊อก
                          </Button>
                        </div> */}
                        {/* {row?.serial_type ? (
                          <Tooltip
                            title="ไม่สามารถแก้ไขสต๊อกได้"
                            placement="top"
                          >
                            <div>
                              <Button
                                variant="contained"
                                color={'yellow'}
                                size={'small'}
                                disabled
                                startIcon={<EditIcon />}
                              >
                                แก้ไขสต๊อก
                              </Button>
                            </div>
                          </Tooltip>
                        ) : (
                          <Button
                            variant="contained"
                            color={'yellow'}
                            size={'small'}
                            onClick={() => {
                              handleOpenEditStock(row?.id);
                              setEditStock(row);
                            }}
                            startIcon={<EditIcon />}
                          >
                            แก้ไขสต๊อก
                          </Button>
                        )} */}
                      </div>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={6}>
                    <div className="text-center">ไม่มีข้อมูล</div>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          component="div"
          rowsPerPageOptions={[5, 10, 25, 100, 500]}
          onRowsPerPageChange={handleChangeRowsPerPage}
          page={page - 1}
          count={total || 1}
          rowsPerPage={size}
          onPageChange={handleChangePage}
        />
      </Paper>
    </div>
  );

  if (product.isLoading || warehouse.isLoading) {
    return <Loading />;
  }
  if (!product.isLoading && product.isCompleted) {
    return (
      <div>
        {renderTitle()}
        <div className="flex justify-between">
          <div className="flex flex-row justify-start pb-4">
            <div>
              <BackButton />
            </div>
            <div className="text-2xl font-semibold ml-6">{warehouse.name}</div>
          </div>
          {renderCSVUploadModal()}
          {renderCSVCheckStock()}

          <div className="flex ">
            {/* <div>
              <FormControlLabel
                control={
                  <Checkbox checked={checkStock} onChange={handleChangeStock} />
                }
                label="ตรวจสต๊อค"
              />
            </div> */}
            {defaultMe?.department?.name === 'แผนกบริหารจัดการ' && (
              <div className="pr-1">{renderCheckStockButton()}</div>
            )}

            {warehouse?.import && (
              <div className="pr-1">{renderAddButtom()}</div>
            )}
            {warehouse?.export && (
              <div className="pr-1">{renderExportButton()}</div>
            )}
            {warehouse?.move && (
              <div className="pr-1">{renderMoveButton()}</div>
            )}

            {warehouse?.exceldata && (
              <div className="pr-1">{renderExelButton()}</div>
            )}
            <div className="px-1"> {renderExportExel()}</div>
            <div>{renderExportHistory()}</div>
          </div>
        </div>
        {renderModalCheckStock()}
        {renderSearch()}
        {renderTable()}
      </div>
    );
  }
  return <Error message={product?.message} />;
}

DetailProductStock.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

DetailProductStock.defaultProps = {
  title: '',
  subtitle: '',
};

export default DetailProductStock;
