import {
  PRODUCT_TRANSACTION_ALL,
  PRODUCT_TRANSACTION_GET,
  PRODUCT_TRANSACTION_DEL,
  PRODUCT_TRANSACTION_PUT,
  PRODUCT_TRANSACTION_POST,
  PRODUCT_TRANSACTION_LOADING,
  PRODUCT_TRANSACTION_ERROR,
  PRODUCT_TRANSACTION_RESET,
} from '../types';

import api from '../../../utils/functions/api';

export const productTransactionCreate = (payload) => async (dispatch) => {
  try {
    console.log('payload in product transaction', payload);
    dispatch({ type: PRODUCT_TRANSACTION_LOADING });
    const { data } = await api.post(
      `${process.env.REACT_APP_API_URL}/product-transaction`,
      {
        ...payload,
      },
    );
    dispatch({ type: PRODUCT_TRANSACTION_POST, payload: data });
  } catch (error) {
    console.error(error?.response?.data);
    dispatch({ type: PRODUCT_TRANSACTION_ERROR });
    throw new Error(error?.response?.data?.error?.message);
  }
};

export const productTransactionAll = (params) => async (dispatch) => {
  try {
    const {
      name = '',
      size = '',
      page = 1,
      transactionType = '',
      date = '',
      startDate = '',
      endDate = '',
      DashBoardDaily = '',
      DashBoardPage = false,
      withLog = '',
      selectDate = '',
      warehouse = '',
    } = params;
    console.log('param', params);
    const { data, status } = await api.get(
      `${process.env.REACT_APP_API_URL}/product-transaction?name=${name}&size=${size}&page=${page}&type=${transactionType}&date=${date}&startDate=${startDate}&endDate=${endDate}&DashBoardDaily=${DashBoardDaily}&DashBoardPage=${DashBoardPage}&selectDate=${selectDate}&withLog=${withLog}&warehouse=${warehouse}`,
    );
    console.log('data', data);
    if (status === 200) {
      dispatch({ type: PRODUCT_TRANSACTION_ALL, payload: data });
    }
  } catch (error) {
    console.error(error);
    dispatch({ type: PRODUCT_TRANSACTION_ERROR });
    throw new Error(error);
  }
};

export const productTransactionReset = () => async (dispatch) => {
  try {
    const data = [];
    dispatch({ type: PRODUCT_TRANSACTION_RESET, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: PRODUCT_TRANSACTION_ERROR });
    throw new Error(error);
  }
};

export const productTransactionGet = (id) => async (dispatch) => {
  try {
    const { data, status } = await api.get(
      `${process.env.REACT_APP_API_URL}/product-transaction/${id}`,
    );
    if (status === 200) {
      dispatch({ type: PRODUCT_TRANSACTION_GET, payload: data });
    }
  } catch (error) {
    console.error(error);
    dispatch({ type: PRODUCT_TRANSACTION_ERROR });
    throw new Error(error);
  }
};

export const productTransactionPut = (id, payload) => async (dispatch) => {
  try {
    dispatch({ type: PRODUCT_TRANSACTION_LOADING });
    const { data } = await api.put(
      `${process.env.REACT_APP_API_URL}/product-transaction/${id}`,
      payload,
    );
    dispatch({ type: PRODUCT_TRANSACTION_PUT, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: PRODUCT_TRANSACTION_ERROR });
    throw new Error(error);
  }
};
export const productTransactionDelete = (id) => async (dispatch) => {
  try {
    dispatch({ type: PRODUCT_TRANSACTION_LOADING });
    const { data } = await api.delete(
      `${process.env.REACT_APP_API_URL}/product-transaction/${id}`,
    );
    console.log('DATA', data);
    dispatch({ type: PRODUCT_TRANSACTION_DEL, payload: data });
  } catch (error) {
    console.error(error);
    dispatch({ type: PRODUCT_TRANSACTION_ERROR });
    throw new Error(error);
  }
};
