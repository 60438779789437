/* eslint-disable no-nested-ternary */
import pdfMake from 'addthaifont-pdfmake';
import 'addthaifont-pdfmake/build/vfs_fonts';
import buddhistEra from 'dayjs/plugin/buddhistEra';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';
import dayjs from 'dayjs';

// import THBText from 'thai-baht-text';

import _ from 'lodash';

const THBText = require('thai-baht-text');
pdfMake.fonts = {
  Sarabun: {
    normal: 'Sarabun-Light.ttf',
    bold: 'Sarabun-Regular.ttf',
    italics: 'Sarabun-LightItalic.ttf',
    bolditalics: 'Sarabun-Italic.ttf',
  },
};
dayjs.locale('th');
dayjs.extend(buddhistEra);
dayjs.extend(LocalizedFormat);

const genBody = (data) => {
  const product = _.map(data?.order, (each, index) => [
    {
      text: index + 1,
      style: 'body3',
      alignment: 'center',
    },
    {
      text: `${each?.product?.type_code || ''}`,
      style: 'body3',
      alignment: 'center',
    },
    {
      text: `${each?.product?.name || ''}`,
      style: 'body3',
      alignment: 'center',
    },
    {
      text: `${each?.cost_price?.toFixed(2) || ''}`,
      style: 'body3',
      alignment: 'center',
    },
    {
      text: `${each?.amount || ''}`,
      style: 'body3',
      alignment: 'center',
    },

    {
      text: `${each?.unit || ''}`,
      style: 'body3',
      alignment: 'center',
    },
    {
      text: each?.cost_price?.toFixed(2) * each?.amount?.toFixed(2) || 0,
      style: 'body3',
      alignment: 'center',
    },
    {
      text: each?.product?.product_type.name || '',
      style: 'body3',
      alignment: 'center',
    },

    // {
    //   text:
    //     (each?.price - each?.discount || 0)
    //       .toFixed(2)
    //       .replace(/\d(?=(\d{3})+\.)/g, '$&,') || '-',
    //   alignment: 'right',
    // },
  ]);
  return product;
};

const DetailImport = async (
  data,
  ArrayProductList,
  setting,
  productTransaction,
) => {
  console.log('Data in quotation', data);
  console.log('setting in quotation', setting);
  console.log('productList in pdf', productTransaction);
  console.log('data in pdf', data?.remark);
  console.log('data', data);

  const sumPrice = _.sumBy(
    data?.order,
    (each) => each.cost_price * each?.amount,
  );
  console.log('sumPrice', sumPrice);

  // const totalPrice = _.sumBy(data, (each) => each.price * each?.amount);
  const sumDiscount = _.sumBy(
    productTransaction,
    (each) => (each?.discount || 0) * (each?.amount || each?.quantity),
  );

  const sumAllPrice =
    sumPrice - sumDiscount + (data?.order_id?.delivery_price || 0);
  const priceEnd = sumAllPrice;

  // const genItemBody = genBody(
  //   data,
  //   //productTransaction?.order || productTransaction?.order_id?.orders,
  //   // productTransaction,
  // );
  const genItemBody = genBody(data);

  console.log('Gen Item Body', genItemBody);

  const documentRef = {
    pageSize: 'A5',
    pageOrientation: 'vertical',
    pageMargins: [20, 270, 20, 50],
    defaultStyle: {
      font: 'Sarabun',
    },
    header: [
      {
        margin: [0, 8, 0, 0],
        table: {
          widths: ['*'],
          alignment: 'center',

          body: [
            [
              {
                text: 'ใบกำกับสินค้าเข้าคลัง',
                border: [false, false, false, false],
                alignment: 'center',
                fontSize: '13',
                bold: true,
              },
            ],
          ],
        },
      },
      {
        margin: [0, 4, 0, 0],
        table: {
          widths: ['*'],
          alignment: 'center',
          body: [
            [
              {
                text: `${setting?.owner?.name}\n ${setting?.nameEng || ''}
                 `,
                style: 'body1',
                alignment: 'center',
                border: [false, false, false, false],
              },
            ],
          ],
        },
      },
      {
        margin: [0, -22, 0, 0],
        table: {
          widths: ['*'],
          alignment: 'center',
          body: [
            [
              {
                text: `${setting?.owner?.address?.house_number || ''} หมู่ ${
                  setting?.owner?.address?.village_number || ''
                } ถนน${setting?.owner?.address?.road || ''} ตำบล${
                  setting?.owner?.address?.subdistrict || ''
                } อำเภอ${setting?.owner?.address?.district || ''} จังหวัด${
                  setting?.owner?.address?.province || ''
                } ${setting?.owner?.address?.postcode || ''}
                 `,
                style: 'body1',
                alignment: 'center',
                border: [false, false, false, false],
              },
            ],
          ],
        },
      },
      {
        margin: [0, -8, 0, 0],
        table: {
          widths: ['*'],
          alignment: 'center',
          body: [
            [
              {
                text: `โทร ${setting?.owner?.telephone} เลขประจำตัวผู้เสียภาษีอากร ${setting?.owner?.tax}\n
                 `,
                style: 'body1',
                alignment: 'center',
                border: [false, false, false, false],
              },
            ],
          ],
        },
      },
      {
        margin: [0, -8, 0, 0],
        table: {
          widths: ['10%', '25%', '20%', '20%', '10%', '15%'],
          alignment: 'center',
          body: [
            [
              {
                text: 'วันที่ ',
                style: 'body3',
                alignment: 'center',
                bold: true,
                border: [false, false, false, false],
              },
              {
                text: `${dayjs(data.date || data.createdAt).format(
                  'DD MMMM BBBB',
                )}`,
                style: 'body3',
                border: [false, false, false, false],
              },
              {
                text: '',
                style: 'body3',
                alignment: 'center',
                border: [false, false, false, false],
              },
              {
                text: '',
                style: 'body3',
                alignment: 'center',
                border: [false, false, false, false],
              },
              {
                text: 'ผู้จำหน่าย',
                style: 'body3',
                alignment: 'center',
                bold: true,
                border: [false, false, false, false],
              },
              {
                text: '',
                style: 'body3',
                alignment: 'center',
                border: [false, false, false, false],
              },
            ],
          ],
        },
      },
      {
        margin: [0, -4, 0, 0],
        table: {
          widths: ['10%', '25%', '20%', '20%', '10%', '15%'],
          alignment: 'center',
          body: [
            [
              {
                text: 'เลขที่ ',
                style: 'body3',
                alignment: 'center',
                bold: true,
                border: [false, false, false, false],
              },
              {
                text: `${data?.volume_no || ''}`,
                style: 'body3',
                alignment: 'left',
                border: [false, false, false, false],
              },
              {
                text: '',
                style: 'body3',
                alignment: 'center',
                border: [false, false, false, false],
              },
              {
                text: '',
                style: 'body3',
                alignment: 'center',
                border: [false, false, false, false],
              },
              {
                text: 'บิลเลขที่',
                style: 'body3',
                alignment: 'center',
                bold: true,
                border: [false, false, false, false],
              },
              {
                text: `${data?.bill_no || ''}`,
                style: 'body3',
                alignment: 'left',
                border: [false, false, false, false],
              },
            ],
          ],
        },
      },
    ],
    content: [
      {
        margin: [-8, -120, -8, 0],
        table: {
          widths: ['5%', '20%', '25%', '8%', '8%', '7%', '12%', '15%'],
          alignment: 'center',
          body: [
            [
              {
                text: 'ที่ ',
                style: 'body3',
                alignment: 'center',
                bold: true,
                border: [true, true, true, true],
              },
              {
                text: 'รหัสสินค้า',
                style: 'body3',
                alignment: 'center',
                border: [true, true, true, true],
              },
              {
                text: 'รายการ',
                style: 'body3',
                alignment: 'center',
                border: [true, true, true, true],
              },
              {
                text: 'ทุน',
                style: 'body3',
                alignment: 'center',
                border: [true, true, true, true],
              },
              {
                text: 'จำนวน',
                style: 'body3',
                alignment: 'center',
                bold: true,
                border: [true, true, true, true],
              },
              {
                text: 'หน่วย',
                style: 'body3',
                alignment: 'center',
                bold: true,
                border: [true, true, true, true],
              },
              {
                text: 'จำนวนเงิน',
                style: 'body3',
                alignment: 'center',
                border: [true, true, true, true],
              },
              {
                text: 'หมวดสินค้า',
                style: 'body3',
                alignment: 'center',
                border: [true, true, true, true],
              },
            ],
            ...genItemBody,
          ],
        },
      },
      {
        margin: [-8, 0, -8, 0],
        table: {
          // headers are automatically repeated if the table spans over multiple pages
          // you can declare how many rows should be treated as headers
          headerRows: 1,

          widths: ['5%', '20%', '25%', '8%', '8%', '7%', '12%', '15%'],

          body: [
            [
              {
                text: `${THBText(sumPrice)}`,
                style: 'body3',
                colSpan: 4,
                alignment: 'center',
                border: [true, false, true, true],
              },
              {
                text: '',
                style: 'body3',
                border: [true, false, true, true],
              },
              {
                text: '',
                style: 'body3',
                border: [true, false, true, true],
              },
              {
                text: '',
                style: 'body3',
                border: [true, false, true, true],
              },
              {
                text: '',
                style: 'body3',
                border: [true, false, true, true],
              },
              {
                text: '',
                style: 'body3',
                border: [true, false, true, true],
              },
              {
                text: 'รวมเงิน',
                border: [true, false, true, true],
                style: 'body3',
                alignment: 'center',
              },
              {
                text:
                  sumPrice.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,') ||
                  '-',
                style: 'body3',
                border: [true, false, true, true],
                alignment: 'center',
              },
            ],
          ],
        },
      },
    ],
    footer: [
      {
        margin: [-20, -40, 0, 0],

        table: {
          alignment: '',

          widths: ['50%', '25%', '25%'],
          body: [
            [
              {
                text: '',
                border: [false, false, false, false],
                margin: [0, 0, 0, 10],
                fontSize: 10,
                alignment: 'center',
              },
              {
                text: 'ผู้จัดทำ',
                border: [false, false, false, false],
                margin: [-10, 0, 0, 10],
                fontSize: 10,
                alignment: 'center',
                bold: true,
              },
              {
                text: 'ผู้ตรวจทาน',
                border: [false, false, false, false],
                margin: [-16, 0, 0, 10],
                fontSize: 10,
                alignment: 'center',
                bold: true,
              },
            ],
            [
              {
                text: '',
                bold: true,
                border: [false, false, false, false],
              },

              {
                text: '.............................',
                border: [false, false, false, false],
                bold: true,
              },
              {
                text: '.............................',
                border: [false, false, false, false],
                alignment: '',
              },
            ],
          ],
        },
      },
    ],

    styles: {
      header: {
        fontSize: 11,
        bold: true,
        alignment: 'right',
      },
      header1: {
        fontSize: 14,
        bold: true,
      },
      body: {
        fontSize: 9,
        alignment: 'left',
        bold: true,
      },
      body1: {
        alignment: 'right',
        fontSize: 9,
      },
      body2: {
        alignment: 'right',
        fontSize: 8,
      },
      body3: {
        alignment: 'left',
        fontSize: 9,
      },
      number: {
        alignment: 'right',
        fontSize: 10,
        bold: true,
      },
    },
    images: {
      logo: setting?.logo?.url || '-',
    },
  };

  pdfMake.createPdf(documentRef).open();
};
export default DetailImport;
