/* eslint-disable implicit-arrow-linebreak */
import pdfMake from 'addthaifont-pdfmake';
import 'addthaifont-pdfmake/build/vfs_fonts';
import buddhistEra from 'dayjs/plugin/buddhistEra';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';
import dayjs from 'dayjs';
import _, { each } from 'lodash';
import { ReportHeaderStock } from './common';
import ReportHeaderExport from './common/ReportHeaderExport';

pdfMake.fonts = {
  Sarabun: {
    normal: 'Sarabun-Light.ttf',
    bold: 'Sarabun-Regular.ttf',
    italics: 'Sarabun-LightItalic.ttf',
    bolditalics: 'Sarabun-Italic.ttf',
  },
};
dayjs.locale('th');
dayjs.extend(buddhistEra);
dayjs.extend(LocalizedFormat);
const genBody = (filterHistoryWarehouse) => {
  const productList = _.map(filterHistoryWarehouse, (product, index) => [
    { text: index + 1, alignment: 'center' },
    {
      text: dayjs(product?.date).format('DD MMM BB '),
      alignment: 'center',
    },
    { text: product?.bill_no, alignment: 'center' },

    { text: `${product?.customer?.name}\n`, alignment: 'center' },
    {
      text: `${_.map(
        product?.order,
        (order) =>
          `${order?.product.name} ${order?.amount} ${order?.product?.unit}\n`,
      )}`,
      alignment: 'center',
    },
  ]);
  return productList;
};

const HistoryEx = async (filterHistoryWarehouse, setting) => {
  // eslint-disable-next-line global-require
  console.log('filterHistoryWarehouse', filterHistoryWarehouse);

  const genItemBody = genBody(filterHistoryWarehouse);
  const documentRef = {
    pageSize: 'A4',
    pageOrientation: 'portait',
    pageMargins: [20, 100, 20, 0],
    defaultStyle: {
      font: 'Sarabun',
      fontSize: '10',
    },
    header: [ReportHeaderExport(setting)],
    content: [
      {
        margin: [0, 12],
        table: {
          alignment: '',
          headerRows: 1,
          widths: ['10%', '18%', '*', '*', '31%'],
          body: [
            [
              {
                text: 'ลำดับที่',
                fillColor: '#d2d4d2',
                alignment: 'center',
                border: [true, true, true, true],
              },
              {
                text: 'วันดำเนินการ',
                fillColor: '#d2d4d2',
                alignment: 'center',
                border: [true, true, true, true],
              },
              {
                text: 'หมายเลขบิล',
                fillColor: '#d2d4d2',
                alignment: 'center',
                border: [true, true, true, true],
              },

              {
                text: 'คู่ค้า',
                fillColor: '#d2d4d2',
                alignment: 'center',
                border: [true, true, true, true],
              },
              {
                text: 'รายการสินค้า',
                fillColor: '#d2d4d2',
                alignment: 'center',
                border: [true, true, true, true],
              },
            ],
            ...genItemBody,
          ],
        },
      },

      {},
      {
        // margin: [0, 12],
      },
    ],

    footer: [],

    styles: {
      headerPage: {
        fontSize: 14,
        bold: true,
        alignment: 'center',
      },
      header: {
        fontSize: 11,
        bold: true,
        alignment: 'center',
      },
    },
  };

  pdfMake.createPdf(documentRef).open();
};
export default HistoryEx;
