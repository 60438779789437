/* eslint-disable no-confusing-arrow */
import appConfig from '../../configs/app';

const columns = [
  {
    label: 'ลำดับที่',
    value: 'index',
  },
  {
    label: 'รหัสสินค้า',
    value: (col) => col.product.type_code,
  },
  {
    label: 'ชื่อสินค้า',
    value: (col) => col.product.name,
  },
  {
    label: 'เหลือ',
    value: 'amount',
  },
  {
    label: 'หน่วย',
    value: (col) => col.product.unit,
  },
];

const query = ({ id }) => ({
  fetchStockLot: true,
  fetchProcess: false,
  page: 1,
  size: appConfig.maxFetchSize,
});

export default { columns, query };
