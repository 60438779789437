/* eslint-disable import/no-unresolved */
import {
  TextField,
  FormControlLabel,
  Checkbox,
  Autocomplete,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
} from '@mui/material';

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import OptionForm from './OptionForm';
// eslint-disable-next-line import/no-unresolved
// eslint-disable-next-line import/extensions
import RichtextEditor from '../../Richtext/RichtextEditor';
import _ from 'lodash';

export function ProductForm({
  product,
  Controller,
  control,
  productType,
  addOption,
  setAddOption,
  addOptionMultiple,
  setAddOptionMultiple,
  selectOption,
  setSelectOption,
  option,
  serialType,
  productDescribe,
  setProductDescribe,
  privatePrice,
  stockInfinite,
  handleChangeSerialType,
  showFront,
  popularProduct,
  brand,
  warehouse,

  // taxInclude,
  // setTaxInclude,
}) {
  const [reload, setReload] = useState(false);
  const [modalFileOpen, setModalFileOpen] = useState(false);
  const [uploadedImage, setUploadedImage] = useState('');

  useEffect(() => {
    if (addOption === false) {
      setAddOptionMultiple(false);
    }

    return () => {};
  }, [addOption]);

  console.log(
    'addOption and addOptionMultiple',
    addOption,
    ':',
    addOptionMultiple,
  );

  return (
    <div className="flex flex-row flex-wrap">
      <div className="p-2 bg-gray-600 text-white my-2 w-full">
        ข้อมูลเบื้องต้นของสินค้า
      </div>
      <div className="w-full px-1 py-2">
        <Controller
          name={'type_code'}
          control={control}
          defaultValue={product ? product.type_code : ''}
          rules={{ required: true }}
          render={({ field }) => (
            <TextField
              {...field}
              label="รหัสสินค้า"
              required
              fullWidth
              size={'small'}
            />
          )}
        />
      </div>
      <div className="w-full lg:w-1/2 px-1 py-2">
        <Controller
          name={'name'}
          control={control}
          defaultValue={product ? product.name : ''}
          rules={{ required: true }}
          render={({ field }) => (
            <TextField
              {...field}
              label="ชื่อสินค้า"
              required
              fullWidth
              size={'small'}
            />
          )}
        />
      </div>
      <div className="w-full lg:w-1/2 px-1 py-2">
        <Controller
          name={'unit'}
          control={control}
          defaultValue={product ? product.unit : ''}
          rules={{ required: true }}
          render={({ field }) => (
            <TextField
              {...field}
              label="หน่วยสินค้า"
              required
              fullWidth
              size={'small'}
            />
          )}
        />
      </div>
      <div className="w-full lg:w-1/2 px-1 py-2">
        <Controller
          name={'product_type'}
          control={control}
          rules={{ required: true }}
          render={({ field }) => (
            <Autocomplete
              size={'small'}
              disableClearable
              {...field}
              onChange={(e, newValue) => {
                field.onChange(newValue);
                setReload(!reload);
              }}
              options={productType?.rows}
              getOptionLabel={(type) => `${type?.name}`}
              required
              renderInput={(params) => (
                <TextField {...params} label="ประเภทสินค้า" required />
              )}
            />
          )}
        />
      </div>
      <div className="w-full lg:w-1/2 px-1 py-2">
        <Controller
          name={'brand'}
          control={control}
          rules={{ required: false }}
          render={({ field }) => (
            <Autocomplete
              size={'small'}
              {...field}
              onChange={(e, newValue) => {
                field.onChange(newValue);
                setReload(!reload);
              }}
              options={brand}
              getOptionLabel={(type) => `${type?.name}`}
              renderOption={(props, type) => (
                <div className="flex gap-2" {...props}>
                  <img src={type?.image?.url || ''} width="40" height="20" />
                  <div className="my-auto pl-2">{`${type?.name}`}</div>
                </div>
              )}
              renderInput={(params) => <TextField {...params} label="แบรนด์" />}
            />
          )}
        />
      </div>
      <div className="w-full pl-1">
        <Controller
          name={'warehouse'}
          control={control}
          // eslint-disable-next-line no-underscore-dangle
          defaultValue={product ? product?.warehouse : ''}
          rules={{ required: false }}
          render={({ field }) => (
            <FormControl sx={{ minWidth: 120 }} fullWidth={true}>
              <InputLabel id="brand" size={'small'}>
                คลังสินค้าที่จะเพิ่ม
              </InputLabel>
              <Select {...field} label="แบรนด์" size={'small'} fullWidth>
                <MenuItem>
                  <MenuItem value={null}>
                    <em>ไม่เลือก</em>
                  </MenuItem>
                </MenuItem>
                {_.size(warehouse?.rows) ? (
                  _.map(warehouse.rows, (row) => (
                    <MenuItem key={row.id} value={row.id}>
                      {row.name}
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem>
                    <MenuItem disabled value="">
                      <em>ไม่มีข้อมูล</em>
                    </MenuItem>
                  </MenuItem>
                )}
              </Select>
            </FormControl>
          )}
        />
      </div>
      {addOption === addOptionMultiple && (
        <div className="w-full px-1 py-2 flex">
          <div className="w-1/2 pr-1">
            <Controller
              name={'cost_price'}
              control={control}
              defaultValue={product ? product.cost_price : 0}
              rules={{ required: false }}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="ต้นทุน(บาท)"
                  fullWidth
                  type="number"
                  size={'small'}
                  // eslint-disable-next-line react/prop-types
                />
              )}
            />
          </div>
          <div className="w-1/2 pl-1">
            <Controller
              name={'price'}
              control={control}
              defaultValue={product ? product.price : 0}
              rules={{ required: false }}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="ราคาขาย(บาท)"
                  fullWidth
                  type="number"
                  size={'small'}
                  // eslint-disable-next-line react/prop-types
                />
              )}
            />
          </div>
        </div>
      )}

      <div className="lg:flex w-full">
        <div className="w-full pl-2 lg:py-2">
          <div className="w-full pl-2">
            <Controller
              name={'private_price'}
              control={control}
              defaultValue={privatePrice}
              rules={{ required: false }}
              render={({ field }) => (
                <FormControlLabel
                  control={<Checkbox {...field} defaultChecked={field.value} />}
                  label="ไม่เเสดงราคาหน้าเว็ป ( ออกใบเสนอราคา )"
                />
              )}
            />
          </div>
          {/* {addOption !== true && (
            <div className="w-full pl-2">
              <Controller
                name={'serial_type'}
                control={control}
                defaultValue={serialType}
                rules={{ required: false }}
                render={({ field }) => (
                  <FormControlLabel
                    control={
                      <Checkbox
                        {...field}
                        onChange={handleChangeSerialType}
                        defaultChecked={field.value}
                      />
                    }
                    label="มี Serial Number"
                  />
                )}
              />
            </div>
          )} */}

          <div className="w-full pl-2">
            <Controller
              name={'stock_infinite'}
              control={control}
              defaultValue={stockInfinite}
              rules={{ required: false }}
              render={({ field }) => (
                <FormControlLabel
                  control={<Checkbox {...field} defaultChecked={field.value} />}
                  label="สต๊อคไม่มีวันหมด ( ตัวอย่างเช่น กาแฟ, สินค้าที่พรีออเด้อได้ )"
                />
              )}
            />
          </div>
        </div>
        <div className="w-full pl-2 lg:py-2">
          <div className="w-full pl-2">
            <Controller
              name={'popular_product'}
              control={control}
              defaultValue={popularProduct}
              rules={{ required: false }}
              render={({ field }) => (
                <FormControlLabel
                  control={<Checkbox {...field} defaultChecked={field.value} />}
                  label="สินค้ายอดนิยม"
                />
              )}
            />
          </div>

          <div className="w-full pl-2">
            <Controller
              name={'show_front'}
              control={control}
              defaultValue={showFront}
              rules={{ required: false }}
              render={({ field }) => (
                <FormControlLabel
                  control={<Checkbox {...field} defaultChecked={field.value} />}
                  label="แสดงสินค้าหน้าเว็ปไซต์"
                />
              )}
            />
          </div>

          {/* <div className="w-full pl-2">
            <Controller
              name={'tax_include'}
              control={control}
              defaultValue={taxInclude}
              rules={{ required: false }}
              render={({ field }) => (
                <FormControlLabel
                  control={<Checkbox {...field} defaultChecked={field.value} />}
                  label="สินค้ารวมภาษีมูลค่าเพิ่มแล้ว"
                />
              )}
            />
          </div> */}
        </div>
      </div>

      <div className="w-full lg:w-1/2 px-1 py-2">
        <Controller
          name={'pre_order'}
          control={control}
          rules={{ required: false }}
          render={({ field }) => (
            <TextField
              {...field}
              label="จำนวนวันในการรอสินค้า กรณีสินค้าเป็นพรีออเด้อ"
              fullWidth
              type="number"
              size={'small'}
              // eslint-disable-next-line react/prop-types
            />
          )}
        />
      </div>

      <div className="w-full lg:w-1/2 px-1 py-2">
        <Controller
          name={'minimum_inventory_size'}
          control={control}
          defaultValue={20}
          rules={{ required: true }}
          render={({ field }) => (
            <TextField
              {...field}
              label="จำนวนสินค้าขั้นต่ำในการแจ้งเตือน"
              required
              fullWidth
              type="number"
              size={'small'}
              // eslint-disable-next-line react/prop-types
            />
          )}
        />
      </div>

      {/* {addOption !== null && !serialType ? (
        <div className="w-full pl-2">
          <FormControlLabel
            value="end"
            defaultValue={addOption}
            control={<Checkbox color="primary" />}
            label="เพิ่มออฟชั่นเสริมหรือรุ่นของสินค้า ( SKU )"
            labelPlacement="end"
            name="addUser"
            onChange={() => {
              setAddOption(!addOption);
            }}
          />
        </div>
      ) : (
        <></>
      )} */}

      {addOptionMultiple !== null && !serialType && addOption ? (
        <div className="w-1/2 pl-2">
          <FormControlLabel
            value="end"
            defaultValue={addOption}
            control={<Checkbox color="primary" />}
            label="ลูกค้าเลือกได้หลายออฟชั่น"
            labelPlacement="end"
            name="addOptionMultiple"
            onChange={() => {
              setAddOptionMultiple(!addOptionMultiple);
            }}
          />
        </div>
      ) : (
        <></>
      )}

      {addOptionMultiple && (
        <div className="w-1/2 pl-2">
          <Controller
            name={'option_threshold'}
            control={control}
            defaultValue={3}
            rules={{ required: addOptionMultiple }}
            render={({ field }) => (
              <TextField
                {...field}
                label="จำนวนออฟชั่นที่เลือกได้"
                fullWidth
                type="number"
                size={'small'}
                // eslint-disable-next-line react/prop-types
              />
            )}
          />
        </div>
      )}

      {addOption === true || option ? (
        <div className="w-full pb-4">
          <OptionForm
            setModalFileOpen={setModalFileOpen}
            setUploadedImage={setUploadedImage}
            uploadedImage={setUploadedImage}
            modalFileOpen={modalFileOpen}
            selectOption={selectOption}
            setSelectOption={setSelectOption}
            option={option}
            addOptionMultiple={addOptionMultiple}
            addOption={addOption}
          />
        </div>
      ) : (
        <></>
      )}
      <div className="p-2 bg-gray-600 text-white mx-1 w-full">
        ข้อมูลการจัดส่ง
      </div>
      <div className="w-full lg:flex pl-2 py-2">
        <div className="w-full lg:w-1/2 pl-2">
          <Controller
            name={'delivery'}
            control={control}
            defaultValue={true}
            rules={{ required: false }}
            render={({ field }) => (
              <FormControlLabel
                control={<Checkbox {...field} defaultChecked={field.value} />}
                label="สามารถจัดส่งได้"
              />
            )}
          />
        </div>
        {addOption === addOptionMultiple && (
          <div className="w-full lg:w-1/2 my-auto pt-2">
            <Controller
              name={'weight'}
              control={control}
              defaultValue={100}
              rules={{ required: true }}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="น้ำหนักสินค้า ( กรัม )"
                  required
                  fullWidth
                  type="number"
                  size={'small'}
                  // eslint-disable-next-line react/prop-types
                />
              )}
            />
            <small className="text-red-400">( ใช้สำหรับคำนวณค่าจัดส่ง )</small>
          </div>
        )}
      </div>
      <div className="w-full px-1 pb-20">
        <div className="p-2 bg-gray-600 text-white my-2">
          รายละเอียด/คำอธิบายสินค้า
        </div>
        <RichtextEditor value={productDescribe} setValue={setProductDescribe} />
      </div>
    </div>
  );
}

ProductForm.propTypes = {
  errors: PropTypes.shape({
    name: PropTypes.string,
    unit: PropTypes.string,
    packing_size: PropTypes.number,
    packing_unit: PropTypes.string,
    inventory: PropTypes.number,
    price: PropTypes.number,
    unit_net: PropTypes.string,
    detail: PropTypes.string,
    type_code: PropTypes.string,
    option: PropTypes.string,
  }),
  product: PropTypes.object,
  productType: PropTypes.object,
  brand: PropTypes.array,
  Controller: PropTypes.func,
  control: PropTypes.object,
  addOption: PropTypes.bool,
  setAddOption: PropTypes.func,
  addOptionMultiple: PropTypes.bool,
  setAddOptionMultiple: PropTypes.func,
  serialType: PropTypes.bool,
  setSerialType: PropTypes.func,
  selectOption: PropTypes.array,
  setSelectOption: PropTypes.func,
  option: PropTypes.array,
  productDescribe: PropTypes.string,
  setProductDescribe: PropTypes.func,
  privatePrice: PropTypes.bool,
  setPrivatePrice: PropTypes.func,
  stockInfinite: PropTypes.bool,
  setStockInfinite: PropTypes.func,
  handleChangeSerialType: PropTypes.func,
  showFront: PropTypes.bool,
  setShowFront: PropTypes.func,
  popularProduct: PropTypes.bool,
  setPopularProduct: PropTypes.func,
  // taxInclude: PropTypes.bool,
  // setTaxInclude: PropTypes.func,
};

ProductForm.defaultProps = {
  product: null,
  productType: null,
  addOption: null,
  setAddOption: null,
  option: null,
};

export default ProductForm;
