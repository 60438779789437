/* eslint-disable arrow-body-style */
/* eslint-disable operator-linebreak */
import React, { useState } from 'react';
import _ from 'lodash';
import { TextField, Button, Tooltip, Autocomplete } from '@mui/material';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import CreateCustomer from '../../Modal/CreateCustomer';

import { HRMS, SPM } from '../../../utils/Proptypes';

export const ExportForm = ({
  errors,
  // order,
  Controller,
  control,
  customers,
  employees,
  // setSelectedProducts,
  invoice,
  id,
  me,
}) => {
  const [reload, setReload] = useState(false);
  const [isModalCreateCustomerOpen, setIsModalCreateCustomerOpen] = useState(
    false,
  );
  const handleOpen = () => setIsModalCreateCustomerOpen(true);
  const handleClose = () => {
    setIsModalCreateCustomerOpen(false);
  };

  const NewCustomer = _.map(customers, (customer, index) => ({
    index: index + 1,
    ...customer,
  }));
  console.log('id in export', id);
  const CustomerExport = _.filter(
    NewCustomer,
    // eslint-disable-next-line consistent-return
    (eachCustomerWarehouse) => {
      if (
        eachCustomerWarehouse?.type?.warehouse === id &&
        eachCustomerWarehouse?.type?.name !== 'ขายออนไลน์ลูกค้าประจำ' &&
        eachCustomerWarehouse?.name !== 'บีโบ้ช็อป' &&
        eachCustomerWarehouse?.name !== 'ฝ่ายผลิตบีโบ้' &&
        eachCustomerWarehouse?.type?.name !== 'หน้าร้านบีโบ้(ลูกค้ารับเข้า)' &&
        eachCustomerWarehouse?.type?.name !== 'ของสด(ขายส่งบีโบ้)'
      ) {
        return eachCustomerWarehouse;
      }
    },
  );
  console.log('CustomerExport ', CustomerExport);

  return (
    <div>
      {/* <div className="flex justify-between">
        <FormControlLabel
          control={<Switch checked={invoice} onChange={handleChangeInvoice} />}
          label="ออกใบเสนอราคา"
        />
        <Tooltip
          title="กดเปิด กรณีต้องการออกใบเสนอราคา ซึ่งระบบจะยังไม่ทำการตัดสต๊อคสินค้า"
          placement="top"
        >
          <IconButton>
            <InfoIcon />
          </IconButton>
        </Tooltip>
      </div> */}
      <CreateCustomer
        isOpen={isModalCreateCustomerOpen}
        handleOnClose={handleClose}
      />
      <div className="flex flex-row flex-wrap">
        <div className="w-full px-1 py-2 flex">
          <div className="flex-grow">
            <Controller
              name={'customer'}
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <Autocomplete
                  size={'small'}
                  {...field}
                  onChange={(e, newValue) => {
                    field.onChange(newValue);
                    setReload(!reload);
                  }}
                  options={CustomerExport}
                  getOptionLabel={(option) =>
                    // eslint-disable-next-line implicit-arrow-linebreak
                    `${option?.name}`
                  }
                  required
                  renderInput={(params) => (
                    <TextField {...params} label="คู่ค้า" required />
                  )}
                />
              )}
            />
          </div>
          <div className="my-auto pl-1  flex-none">
            <Tooltip
              title="เพิ่มลูกค้ากรณีที่ยังไม่มีข้อมูลในระบบ"
              placement="bottom"
            >
              <Button variant="outlined" onClick={handleOpen}>
                เพิ่มคู่ค้า
              </Button>
            </Tooltip>
          </div>
        </div>
        <div className="w-full px-1 py-2">
          <Controller
            name={'volume_no'}
            control={control}
            rules={{ required: false }}
            render={({ field }) => (
              <TextField {...field} label="เล่มที่" fullWidth size={'small'} />
            )}
          />
        </div>
        <div className="w-full px-1 py-2">
          <Controller
            name={'bill_no'}
            control={control}
            rules={{ required: false }}
            render={({ field }) => (
              <TextField
                {...field}
                label="หมายเลขบิล"
                fullWidth
                size={'small'}
              />
            )}
          />
        </div>
        <div className="w-full px-1 py-1">
          <div className="py-1">
            {invoice ? 'วันที่ออกใบเสนอราคา' : 'วันส่งออกสินค้า'}
          </div>
          <Controller
            name={'date'}
            control={control}
            defaultValue={dayjs(new Date()).locale('th').format('YYYY-MM-DD')}
            rules={{ required: true }}
            render={({ field }) => (
              <TextField
                {...field}
                type="date"
                fullWidth
                size={'small'}
                helperText={errors.expirationDate && 'กรุณาใส่ข้อมูล'}
              />
            )}
          />
        </div>
        <div className="w-full px-1 py-2">
          <Controller
            name={'employee'}
            control={control}
            rules={{ required: false }}
            defaultValue={
              `${me?.userData?.firstname} ${me?.userData?.lastname}` ||
              employees[0]
            }
            render={({ field }) => (
              <TextField
                {...field}
                label="ดำเนินการโดย"
                disabled
                fullWidth
                size={'small'}
              />
            )}
          />
        </div>
        <div className="w-full px-1 py-2">
          <Controller
            name={'remark'}
            control={control}
            rules={{ required: false }}
            render={({ field }) => (
              <TextField
                {...field}
                label="หมายเหตุ"
                fullWidth
                size={'small'}
                rows={3}
                multiline={true}
                helperText={errors.remark && 'กรุณาใส่ข้อมูล'}
              />
            )}
          />
        </div>
        {/* {!invoice && (
          <div className="w-full px-1 py-1">
            <div className="py-1">ประเภทการขาย</div>
            <Controller
              name={'sale_type'}
              control={control}
              defaultValue="online"
              rules={{ required: true }}
              render={({ field }) => (
                <FormControl sx={{ minWidth: 120 }} fullWidth={true}>
                  <RadioGroup
                    {...field}
                    label="ประเภทการขาย"
                    size={'small'}
                    required
                  >
                    <FormControlLabel
                      value="online"
                      control={<Radio />}
                      label="ขายออนไลน์"
                    />
                    <FormControlLabel
                      value="storefront"
                      control={<Radio />}
                      label="ขายหน้าร้าน"
                    />
                    <FormControlLabel
                      value="install"
                      control={<Radio />}
                      label="ติดตั้ง"
                    />
                    <FormControlLabel
                      value="other"
                      control={<Radio />}
                      label="อื่นๆ"
                    />
                  </RadioGroup>
                </FormControl>
              )}
            />
          </div>
        )} */}
      </div>
    </div>
  );
};

ExportForm.propTypes = {
  errors: PropTypes.shape({
    product_transaction_type: PropTypes.object,
    orderNumber: PropTypes.billNumber,
    customer: PropTypes.array,
    source: PropTypes.object,
    quantity: PropTypes.object,
    price: PropTypes.object,
    product_stock_lots: PropTypes.object,
    warehouse: PropTypes.object,
    product_type: PropTypes.array,
    productionDate: PropTypes.object,
    expirationDate: PropTypes.object,
    receiptDate: PropTypes.object,
    sellDate: PropTypes.object,
    remark: PropTypes.object,
    recipientName: PropTypes.object,
    sellName: PropTypes.object,
    trackingNumber: PropTypes.object,
    sellPrice: PropTypes.object,
    shippingCost: PropTypes.object,
    discount: PropTypes.object,
    payment: PropTypes.payment,
  }),
  product: PropTypes.array,
  productType: PropTypes.array,
  productTransactionType: PropTypes.array,
  order: PropTypes.oneOf([SPM.Order, SPM.OrderArray]),
  Controller: PropTypes.func,
  control: PropTypes.object,
  type: PropTypes.object,
  setType: PropTypes.func,
  selectProduct: PropTypes.array,
  setSelectProduct: PropTypes.func,
  filterProduct: PropTypes.object,
  setFilterProduct: PropTypes.func,
  customers: PropTypes.array,
  departments: HRMS.departmentArray,
  watch: PropTypes.func.isRequired,
  products: PropTypes.object,
  selectedProducts: PropTypes.array,
  setSelectedProducts: PropTypes.func,
  employees: PropTypes.array,
  productTypes: PropTypes.object,
  online: PropTypes.bool,
  setOnline: PropTypes.func,
  me: PropTypes.object,
  handleChangeInvoice: PropTypes.func,
  invoice: PropTypes.bool,
};

ExportForm.defaultProps = {
  product: null,
  productType: null,
  productTransactionType: null,
  customer: null,
  department: null,
  me: null,
  type: null,
  selectProduct: null,
  filterProduct: null,
};

export default ExportForm;
