/* eslint-disable no-underscore-dangle */
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import {
  Button,
  Card,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import { useForm, Controller } from 'react-hook-form';
import PropTypes from 'prop-types';
import _, { before } from 'lodash';
import * as actions from '../../redux/actions';
import {
  ExportForm,
  IncreaseProductForm,
  MaterialStockForm,
} from '../../components/Forms';
import Loading from '../../components/Loading';
import { Error } from '../../components/Error';
import { ViewTitle } from '../../components/ViewTitle';
import { BackButton } from '../../components/Button';
import { MoveForm } from '../../components/Forms/WMS/MoveForm';
import MoveProductForm from '../../components/Forms/WMS/MoveProductForm';

const defaultValues = {
  type_code: '',
  name: '',
  description: '',
};

const AddMaterialStockLot = ({ title, subtitle }) => {
  const dispatch = useDispatch();
  const product = useSelector((state) => state.product);
  const materialType = useSelector((state) => state.materialType);
  const warehouse = useSelector((state) => state.warehouse);
  const materialTransactionType = useSelector(
    (state) => state.materialTransactionType,
  );
  console.log('Place', warehouse);

  const materialStockLot = useSelector((state) => state.materialStockLot);
  const customer = useSelector((state) => state.customer);
  const department = useSelector((state) => state.department);
  const employee = useSelector((state) => state.employee);
  const me = useSelector((state) => state.me);
  const [selectProduct, setSelectProduct] = useState();
  const [productList, setProductList] = useState([]);
  const [reload, setReload] = useState(false);
  const [selectSerial, setSelectSerial] = useState([]);
  const history = useHistory();
  const {
    control,
    formState: { errors },
    handleSubmit,
    setValue,
    reset,
  } = useForm();
  const [name] = useState('');
  const [page] = useState(1);
  const [size] = useState(5);
  const [type, setType] = useState(null);
  const [selectMaterial, setSelectMaterial] = useState(null);
  const [filterProduct, setFilterProduct] = useState(null);
  const [filterPlace, setFilterPlace] = useState(null);
  const [defaultMe, setDefaultMe] = useState(me?.userData);
  const { id } = useParams();
  console.log('TTTT', id);

  useEffect(() => {
    dispatch(actions.productAll(id));
    dispatch(actions.materialTypeAll(''));
    dispatch(actions.productTransactionAll(''));
    dispatch(actions.productTransactionGet(''));
    dispatch(actions.employeeAll(''));
    dispatch(actions.warehouseAll(''));
    dispatch(actions.meGet(''));
    dispatch(actions.customerAll(''));
    dispatch(actions.departmentAll(''));
    dispatch(actions.userGet(''));
    setDefaultMe(me?.userData);
    return () => {};
  }, [name, page, size]);

  useEffect(() => {
    const NewProduct = _.map(product?.rows, (_product, index) => ({
      index: index + 1,
      ..._product,
    }));
    console.log('NewProduct', NewProduct);
    const warehouseproductIndex = [];
    _.map(NewProduct, (each) => {
      const selectedProduct = each;
      _.map(each.warehouses, (wh) => {
        if (wh.warehouse === id) {
          selectedProduct.inventory = wh.inventory;
          warehouseproductIndex.push(selectedProduct);
        }
      });
    });
    const filterinventory = _.map(
      _.filter(warehouseproductIndex, (each) => each?.inventory !== 0),
    );
    console.log('filterinventory', filterinventory);
    setValue('productMove', filterinventory);
    return () => {};
  }, [product]);

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  const onSubmit = async (data, event) => {
    if (productList?.length === 0) {
      alert('กรุณาเพิ่มสินค้าก่อน');
    } else {
      try {
        const preprocessedData = {
          ...data,
          before_warehouse: id,
          after_warehouse: '66cbeb83fb9f13001236a44a',
          employee: defaultMe?._id,
          product_transaction_type: 'ย้าย',
          order: _.map(productList, (each) => ({
            // eslint-disable-next-line no-underscore-dangle
            product: each.product._id,
            amount: each.inventory,
            price: each.price,
            remark: each.remark,
            serial:
              _.map(each.serials, (Serial) => ({
                serialNumber: Serial.id,
                name: Serial.name,
              })) || null,
          })),
        };
        console.log('Data', data);
        console.log('preprocessedData', preprocessedData);
        event.preventDefault();
        await dispatch(actions.productTransactionCreate(preprocessedData));
        reset(defaultValues);
        alert('สำเร็จ');

        history.goBack();
      } catch (error) {
        console.error(error);
      }
    }
  };
  const handleDeleteProduct = (index) => {
    productList.splice(index, 1);
    setProductList(productList);
    setReload(!reload);
  };

  const renderForm = () => (
    <Card className="p-6">
      <div className="py-2">เพิ่มข้อมูล</div>
      <MoveForm
        Controller={Controller}
        control={control}
        errors={errors}
        id={id}
        customers={customer.rows}
        employees={employee.rows}
        me={me}
      />
    </Card>
  );
  const renderAddProductForm = () => (
    <Card className="p-6">
      <div className="py-2">เพิ่มสินค้า</div>
      <MoveProductForm
        Controller={Controller}
        // control={control}
        errors={errors}
        // watch={watch}
        customers={customer.rows}
        product={product.rows}
        // productType={productType.rows}
        employees={employee.rows}
        // productTransactionType={productTransactionType.rows}
        // selectedProducts={selectedProducts}
        // setSelectedProducts={setSelectedProducts}
        // type={type}
        // setType={setType}
        selectProduct={selectProduct}
        setSelectProduct={setSelectProduct}
        filterProduct={filterProduct}
        setFilterProduct={setFilterProduct}
        productList={productList}
        setProductList={setProductList}
        reload={reload}
        setReload={setReload}
        selectSerial={selectSerial}
        setSelectSerial={setSelectSerial}
      />
    </Card>
  );
  const displayProduct = () => (
    <TableContainer>
      <Table size="small" className="border-lg">
        <TableHead className="bg-yellow-100">
          <TableRow>
            <TableCell>
              <div className="font-bold">ลำดับที่</div>
            </TableCell>
            <TableCell>
              <div className="font-bold"> ชื่อสินค้า</div>
            </TableCell>
            <TableCell>
              <div className="font-bold"> จำนวน</div>
            </TableCell>
            <TableCell>
              <div className="font-bold"> ราคาต่อหน่วย</div>
            </TableCell>
            <TableCell>
              <div className="font-bold"> ดำเนินการ</div>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {!_.isEmpty(productList) ? (
            productList.map((_product, index) => (
              <>
                <TableRow key={index}>
                  <TableCell>{index + 1}</TableCell>
                  <TableCell>
                    <div>{_product?.product.name}</div>
                    {_product?.remark && <div>( {_product?.remark} )</div>}
                  </TableCell>
                  <TableCell>{_product?.inventory}</TableCell>
                  <TableCell>{_product?.price}</TableCell>
                  <TableCell>
                    <Button
                      color={'error'}
                      variant="contained"
                      size={'small'}
                      onClick={() => handleDeleteProduct(index)}
                    >
                      ลบ
                    </Button>
                  </TableCell>
                </TableRow>

                {/* <TableRow>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell>ยอดเงินก</TableCell>
                  <TableCell>
                    <div className="w-full flex justify-end">1000</div>
                  </TableCell>
                </TableRow> */}
                {/* <TableRow>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell>ส่วนลด</TableCell>
                  <TableCell>
                    <div className="w-full flex justify-end">-</div>
                  </TableCell>
                </TableRow> */}
                {/* <TableRow>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell></TableCell>
                  <TableCell>ยอดเงินหลังหักส่วนลด</TableCell>
                  <TableCell>
                    <div className="w-full flex justify-end">800</div>
                  </TableCell>
                </TableRow> */}
              </>
            ))
          ) : (
            <TableRow key={''}>
              <TableCell colSpan={6}>
                <div className="text-center">ไม่มีรายการ</div>
              </TableCell>
            </TableRow>
          )}
          <TableRow>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
          </TableRow>
          <TableRow>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell>จำนวนเงินทั้งสิ้น</TableCell>
            <TableCell>
              <div className="w-full flex justify-end">
                {_.sumBy(
                  productList,
                  (eachProduct) =>
                    // eslint-disable-next-line implicit-arrow-linebreak
                    parseFloat(eachProduct?.inventory) *
                    parseFloat(eachProduct?.price),
                )}
              </div>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
  const renderShowProductList = () => (
    <Card className="p-6">
      <div className="py-2">รายการสินค้า</div> {displayProduct()}
    </Card>
  );

  if (customer.isLoading || product.isLoading || employee.isLoading) {
    return <Loading />;
  }
  if (
    !customer.isLoading &&
    customer.isCompleted &&
    !product.isLoading &&
    product.isCompleted &&
    !employee.isLoading &&
    employee.isCompleted
  ) {
    return (
      <div>
        <div className="flex justify-between">
          <div>{renderTitle()}</div>
        </div>
        <div className="flex flex-row justify-start pb-4">
          <div>
            <BackButton />
          </div>
          <div className="text-lg font-semibold ml-6">
            สร้างการส่งออกสินค้า(บีโบ้)
          </div>
        </div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="md:flex">
            <div className="w-full md:w-1/3 pr-4">{renderForm()}</div>
            <div className="w-full md:w-2/3 pt-4 md:pt-0">
              {renderAddProductForm()}
              <div className="w-full pt-4">{renderShowProductList()}</div>
            </div>
          </div>
          <div className="flex flex-row justify-end gap-1 py-4">
            <Button variant="contained" type="submit" startIcon={<SaveIcon />}>
              บันทึก
            </Button>
          </div>
        </form>
      </div>
    );
  }
  return <Error message={customer?.message} />;
};

AddMaterialStockLot.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

AddMaterialStockLot.defaultProps = {
  title: '',
  subtitle: '',
};

export default AddMaterialStockLot;
