import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, Link } from 'react-router-dom';
import {
  Card,
  Button,
  CardContent,
  Table,
  TableHead,
  TableCell,
  TableBody,
  TableRow,
} from '@mui/material';
import api from '../../configs/api';

import { useForm, Controller } from 'react-hook-form';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { CSVUpload } from '../../components/CSVUpload';

import Loading from '../../components/Loading';
import { Error } from '../../components/Error';
import { ViewTitle } from '../../components/ViewTitle';
import * as actions from '../../redux/actions';

function AuditStockReduce({ title, subtitle }) {
  const warehouse = useSelector((state) => state.warehouse);
  const [csvData, setCsvData] = useState([]);
  const [isReady, setIsReady] = useState(true);
  const [auditResult, setAuditResult] = useState([]);
  const dispatch = useDispatch();
  const params = useParams();

  useEffect(() => {
    dispatch(actions.warehouseGet(params.id));

    return () => {};
  }, []);

  const renderTitle = () => <ViewTitle title={title} subtitle={subtitle} />;

  const handleAddFromFile = async () => {
    try {
      setIsReady(false);
      const NewCSVData = _.map(csvData, (eachCSV) => {
        const payload = {
          ...eachCSV,
          product_transaction_type: 'หน้าร้าน',
          warehouse: params.id,
          price:
            eachCSV?.price === ''
              ? 0
              : parseFloat(eachCSV?.price.replaceAll(',', '')),
          inventory:
            eachCSV?.inventory === ''
              ? 0
              : parseFloat(eachCSV?.inventory.replaceAll(',', '')),
        };
        return payload;
      });
      const { data } = await api.post(
        `${process.env.REACT_APP_API_URL}/product-transaction/audit-stock-reduce`,
        {
          arr: NewCSVData,
        },
      );
      console.log('Result', data);
      setAuditResult(data);
      setIsReady(true);
    } catch (error) {
      alert(`ไม่สามารถดำเนินการได้ ${error.message}`);
    }
  };
  const auditNoSuccess = _.filter(
    auditResult,
    // eslint-disable-next-line consistent-return
    (eachAuditResult) => {
      if (eachAuditResult?.success === false) {
        return eachAuditResult;
      }
    },
  );
  console.log('auditNoSuccess', auditNoSuccess);

  if (warehouse.isLoading) {
    return <Loading />;
  }

  if (!warehouse.isLoading && warehouse.isCompleted) {
    return (
      <div>
        <div className="flex justify-between">
          <div>{renderTitle()}</div>
        </div>
        <div>
          <Card>
            <CardContent>
              <div className=" text-xl">อัพโหลดข้อมูล</div>
              <div className="py-2 font-system">
                อัพโหลดไฟล์ .csv โดยมีโครงสร้างตามไฟล์เทมเพลต แถวที่ 1-2
                และแถวสุดท้าย จะไม่ถูกนำมาคิดในระบบ <br />
                <Button variant="contained">
                  <Link to="/filetemplate/LotStock.csv" target="_blank">
                    ดาวน์โหลดเทมเพลต
                  </Link>
                </Button>
              </div>
              <div className="py-2">
                <div className="flex justify-center">
                  <div>
                    <CSVUpload setCsvData={setCsvData} />
                    {!_.isEmpty(csvData) && (
                      <div>
                        <p className="font-system my-1">
                          พบข้อมูล {_.size(csvData)} รายการ
                        </p>
                        <Button
                          color="primary"
                          variant="contained"
                          type="button"
                          onClick={handleAddFromFile}
                        >
                          บันทึก
                        </Button>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </CardContent>
          </Card>

          {!isReady && <Loading />}
          {!_.isEmpty(auditNoSuccess) && (
            <Table>
              <TableHead>
                <TableCell>รายการที่</TableCell>
                <TableCell>รหัส</TableCell>
                <TableCell>สามารถเพิ่มได้</TableCell>
                <TableCell>รายละเอียด</TableCell>
              </TableHead>
              <TableBody>
                {_.map(auditNoSuccess, (each, index) => (
                  <TableRow>
                    <TableCell>{index + 1}</TableCell>
                    <TableCell>{each?.type_code}</TableCell>
                    <TableCell>{each?.success ? 'ใช่' : 'ไม่'}</TableCell>
                    <TableCell>{each?.message}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          )}
        </div>
      </div>
    );
  }
  return <Error message={warehouse?.message} />;
}

AuditStockReduce.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

AuditStockReduce.defaultProps = {
  title: '',
  subtitle: '',
};

export default AuditStockReduce;
