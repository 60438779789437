/* eslint-disable no-nested-ternary */
import pdfMake from 'addthaifont-pdfmake';
import 'addthaifont-pdfmake/build/vfs_fonts';
import buddhistEra from 'dayjs/plugin/buddhistEra';
import LocalizedFormat from 'dayjs/plugin/localizedFormat';
import dayjs from 'dayjs';

// import THBText from 'thai-baht-text';

import _ from 'lodash';

const THBText = require('thai-baht-text');
pdfMake.fonts = {
  Sarabun: {
    normal: 'Sarabun-Light.ttf',
    bold: 'Sarabun-Regular.ttf',
    italics: 'Sarabun-LightItalic.ttf',
    bolditalics: 'Sarabun-Italic.ttf',
  },
};
dayjs.locale('th');
dayjs.extend(buddhistEra);
dayjs.extend(LocalizedFormat);

const genBody = (data) => {
  const product = _.map(data?.order, (each, index) => [
    {
      text: `${dayjs(data.date || data.createdAt).format('DD/MM/BBBB')}`,
      fontSize: 11,
    },
    {
      text: `${each?.product?.type_code || ''}`,
      alignment: '',
      fontSize: 11,
    },
    {
      text: `${each?.product?.name || ''}`,
      fontSize: 11,
      alignment: '',
    },

    {
      text: `${each?.amount || ''}`,
      fontSize: 11,
      alignment: 'center',
    },
    {
      text: each?.cost_price || 0,
      fontSize: 11,
      alignment: 'center',
    },

    // {
    //   text:
    //     (each?.price - each?.discount || 0)
    //       .toFixed(2)
    //       .replace(/\d(?=(\d{3})+\.)/g, '$&,') || '-',
    //   alignment: 'right',
    // },
  ]);
  return product;
};

const SaleCutStock = async (
  data,
  ArrayProductList,
  setting,
  productTransaction,
) => {
  console.log('Data in quotation', data);
  console.log('setting in quotation', setting);
  console.log('productList in pdf', productTransaction);
  console.log('data in pdf', data?.remark);
  console.log('data', data);

  const sumPrice = _.sumBy(
    data?.order,
    (each) => each.cost_price * each?.amount,
  );
  console.log('sumPrice', sumPrice);

  // const totalPrice = _.sumBy(data, (each) => each.price * each?.amount);
  const sumDiscount = _.sumBy(
    productTransaction,
    (each) => (each?.discount || 0) * (each?.amount || each?.quantity),
  );

  const sumAllPrice =
    sumPrice - sumDiscount + (data?.order_id?.delivery_price || 0);
  const priceEnd = sumAllPrice;
  const taxTotal =
    setting?.tax_number === 0 ? 0 : (priceEnd * setting?.tax_number || 7) / 100;

  // const genItemBody = genBody(
  //   data,
  //   //productTransaction?.order || productTransaction?.order_id?.orders,
  //   // productTransaction,
  // );
  const genItemBody = genBody(data);

  console.log('Gen Item Body', genItemBody);

  const documentRef = {
    pageSize: 'A5',
    pageOrientation: 'vertical',
    pageMargins: [20, 270, 20, 50],
    defaultStyle: {
      font: 'Sarabun',
    },
    header: [
      {
        margin: [0, 15, 0, 0],
        table: {
          widths: ['*'],
          alignment: 'center',

          body: [
            [
              {
                text: 'ใบตัดสต๊อก',
                border: [false, false, false, false],
                alignment: 'center',
                fontSize: '13',
                bold: true,
              },
            ],
          ],
        },
      },
    ],
    content: [
      {
        margin: [0, -200, 0, 0],
        table: {
          widths: ['20%', '30%', '30%', '10%', '10%'],
          alignment: 'center',
          body: [
            [
              {
                text: 'วันที่ ',
                fontSize: 11,
                alignment: 'center',
                bold: true,
                border: [true, true, true, true],
              },
              {
                text: 'รหัสสินค้า',
                bold: true,
                fontSize: 11,
                alignment: 'center',
                border: [true, true, true, true],
              },
              {
                text: 'รายการ',
                style: 'body3',
                fontSize: 11,
                bold: true,
                alignment: 'center',
                border: [true, true, true, true],
              },
              {
                text: 'จำนวน',
                fontSize: 11,
                alignment: 'center',
                bold: true,
                border: [true, true, true, true],
              },

              {
                text: 'ราคา',
                bold: true,
                fontSize: 11,
                alignment: 'center',
                border: [true, true, true, true],
              },
            ],
            ...genItemBody,
          ],
        },
      },
    ],
    // styles: {
    //   header: {
    //     fontSize: 11,
    //     bold: true,
    //     alignment: 'right',
    //   },
    //   header1: {
    //     fontSize: 14,
    //     bold: true,
    //   },
    //   body: {
    //     fontSize: 9,
    //     alignment: 'left',
    //     bold: true,
    //   },
    //   body1: {
    //     alignment: 'right',
    //     fontSize: 9,
    //   },
    //   body2: {
    //     alignment: 'right',
    //     fontSize: 8,
    //   },
    //   body3: {
    //     alignment: 'left',
    //     fontSize: 9,
    //   },
    //   number: {
    //     alignment: 'right',
    //     fontSize: 10,
    //     bold: true,
    //   },
    // },
    // images: {
    //   logo: setting?.logo?.url || '-',
    // },
  };

  pdfMake.createPdf(documentRef).open();
};
export default SaleCutStock;
