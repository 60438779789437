import api from '../../../utils/functions/api';

import {
  LOG_ALL,
  LOG_CREATE,
  LOG_GET,
  LOG_UPDATE,
  LOG_DELETE,
  LOG_ERROR,
} from '../types';

// faker.locale = "th"

export const getAlllog = ({
  name = '',
  size = '',
  page = 1,
  startDate = '',
  selectWarehouse = '',
}) => async (dispatch) => {
  try {
    const { data, status } = await api.get(
      `${process.env.REACT_APP_API_URL}/log?name=${name}&size=${size}&page=${page}&startDate=${startDate}&selectWarehouse=${selectWarehouse}`,
    );
    console.log('Data in log', data);
    console.log('Name', name);

    if (status === 200) {
      dispatch({ type: LOG_ALL, payload: data });
    }
  } catch (error) {
    console.error(error);
    dispatch({ type: LOG_ERROR });
    throw new Error(error);
  }
};

export const getOnelog = (id) => async (dispatch) => {
  await api.get(`${process.env.REACT_APP_API_URL}/log/${id}`).then((res) => {
    console.log('Request Server to Get One log');
    if (res.data) {
      dispatch({ type: LOG_GET, payload: res.data });
    } else {
      dispatch({ type: LOG_GET, payload: null });
    }
  });
};
export const createOnelog = (payload) => async (dispatch) => {
  await api.post(`${process.env.REACT_APP_API_URL}/log/`, payload).then(() => {
    console.log('Request Server to Create New log');
    dispatch({ type: LOG_CREATE });
  });
};

export const convertLogFromBooking = (bookingId) => async (dispatch) => {
  await api
    .post(`${process.env.REACT_APP_API_URL}/log/transfer/${bookingId}`)
    .then(() => {
      console.log('Request Server To Transfer Booking into log');
      dispatch({ type: LOG_CREATE });
    });
};

export const editOnelog = (id, payload) => async (dispatch) => {
  await api
    .put(`${process.env.REACT_APP_API_URL}/log/${id}`, payload)
    .then(() => {
      console.log('Request Server to edit log');
      dispatch({ type: LOG_UPDATE });
    });
};

export const deleteOnelog = (id) => async (dispatch) => {
  await api.delete(`${process.env.REACT_APP_API_URL}/log/${id}`).then(() => {
    console.log('Request Server to Delete One Log');
    dispatch({ type: LOG_DELETE, payload: null });
  });
};
